import {
  IonPage,
  IonContent,
  IonTitle,
  IonGrid,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import styles from "./CodeSignIn.module.css";
import CodeNumber from "../../../components/CodeNumber/CodeNumber";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";

const CodeSignIn: React.FC = () => {
  const { url, presentToast, setCode } = useContext(AuthInfoContext);
  const history = useHistory();
  const backTo = () => {
    history.replace('/Main')
  };
  const sendCode = async (codeSend: string) => {
    const respuesta = await axios.post(
      url + "organization/validateotp",
      { code: codeSend },
      {
        validateStatus: function (status) {
          return status < 500;
        },
      }
    );
    if (respuesta.data.authorized) {
      presentToast(respuesta.data.message, checkmarkCircleOutline, "success",'top');
      setCode(codeSend)
      history.replace("/SignIn");
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger",'top');
    }
  };
  const handlerCode = (codeHandler: string) => {
    if (codeHandler.length === 8) {
      sendCode(codeHandler);
    } else {
      presentToast('Ingresa un codigo valido para continuar', alertCircleOutline, "danger",'top',1500);
    }
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.codeButtons}>
            <IonButton
              shape="round"
              onClick={backTo}
            >
              <IonIcon
                className={styles.codeIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className={
          styles.contenedor +
          " ion-display-flex ion-text-center ion-justify-content-center ion-align-items-center "
        }
      >
        <IonGrid className={styles.codeContainer}>
          <IonTitle className={styles.codeTitle}>Ingresa tu codigo</IonTitle>
          <CodeNumber length={8} sendCode={handlerCode} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CodeSignIn;
