import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";
import React, { useContext, useRef } from "react";
import { useHistory } from "react-router";
import AuthInfoContext from "../../../context/authinfo-context";
import { Organization } from "../../../context/interfaces-context";
import styles from "./CreateOrganization.module.css";

const CreateOrganization: React.FC = () => {
  const { token, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory();
  const submitButton = useRef<HTMLIonButtonElement>();
  const name = useRef<string | undefined>("");
  const rut = useRef<string | undefined>("");
  const address = useRef<string | undefined>("");
  const checkValues = () => {
    if (name.current && rut.current && address.current) {
      submitButton.current?.removeAttribute("disabled");
    }
  };
  const signinUser = async (organization: Organization) => {
    const respuesta = await axios.post(url + "organization", organization, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (respuesta.status === 201) {
      presentToast(
        respuesta.data.message,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      history.goBack();
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };
  const submit = () => {
    if (name.current && rut.current && address.current) {
      signinUser({
        name: name.current,
        address: address.current,
        vat: rut.current,
      });
    } else {
      presentToast(
        "Ingrese las datos para poder registrarse",
        alertCircleOutline,
        "danger",
        "top"
      );
    }
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.signinButtons}>
            <IonButton
              shape="round"
              routerLink="/Main"
              routerDirection="back"
            >
              <IonIcon
                className={styles.signinIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className={styles.container}>
          <IonTitle className={styles.signinTitle} size="large">
            CREAR ORGANIZACIÓN
          </IonTitle>
          <IonIcon
            className={styles.organizationIcon}
            icon="assets/icons/logo-companies.svg"
          />
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Rut</IonLabel>
                <IonInput
                  placeholder="Rut"
                  inputMode="numeric"
                  onIonChange={(e) => {
                    checkValues();
                    rut.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Nombre</IonLabel>
                <IonInput
                  placeholder="Nombre"
                  onIonChange={(e) => {
                    checkValues();
                    name.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Dirección</IonLabel>
                <IonInput
                  placeholder="Direccion"
                  onIonChange={(e) => {
                    checkValues();
                    address.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.signinButton}
                expand="block"
                fill="outline"
                onClick={submit}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateOrganization;
