import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import styles from "./AdminOrganizationSensors.module.css";
import { useHistory } from "react-router";
import { addCircleOutline } from "ionicons/icons";
import OrganizationSensorsList from "../../../../components/Lists/SuperAdmin/SensorsList/OrganizationSensorsList";

const AdminOrganizationSensors: React.FC = () => {
  const history = useHistory();
  const backTo = () => {
    history.replace("/Main");
  };
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const codeUserModal = useRef<HTMLIonModalElement>(null);
  const [title, setTitle] = useState<string>("Numero Serial");
  const [version, setVersion] = useState<string>("Version Firmware");
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.adminuserBarButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.adminuserBarIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className={styles.homeHeaderButtons}>
            <IonButton
              id="open-modal"
              className={styles.homeButtonIcon}
              shape="round"
              onClick={() => codeUserModal.current?.present()}
            >
              <IonIcon className={styles.homeIcons} icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.adminuserTitleContainer}>
        <IonTitle className={styles.adminuserTitle}>SENSORES</IonTitle>
      </IonGrid>
      <IonGrid className={styles.userListContainer}>
      <IonRow className={styles.userListHeader}>
          <IonCol
            size="5.4"
            onClick={() =>
              title === "Codigo"
                ? setTitle("Numero Serial")
                : setTitle("Codigo")
            }
          >
            <IonText className="ion-vertical-align-middle">{title}</IonText>
          </IonCol>
          <IonCol size="1">
            <IonText>ID</IonText>
          </IonCol>
          <IonCol size="3.4">
            <IonText>Estado del sensor</IonText>
          </IonCol>
          <IonCol
            size="2.2"
            onClick={() =>
              version === "Version Firmware"
                ? setVersion("Version Hardware")
                : setVersion("Version Firmware")
            }
          >
            <IonText>{version}</IonText>
          </IonCol>
        </IonRow>
        <OrganizationSensorsList title={title} version={version} id={id as string}/>
      </IonGrid>
    </IonPage>
  );
};

export default AdminOrganizationSensors;
