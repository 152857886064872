import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import styles from "./MosquitoUsersList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import ListLoading from "../../../Loading/ListLoading";
import { useHistory } from "react-router";
import { UserMqtt, encodeB64 } from "../../../../context/interfaces-context";

const MosquitoUsersList: React.FC<{}> = () => {
  const history = useHistory();
  const { url, token, socket } = useContext(AuthInfoContext);
  const [items, setItems] = useState<UserMqtt[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    console.log(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test("hola"));
    socket?.on("usersMQTTChanged", () => {
      console.log("changed in users mqtt");
      hasNextPage.current = true;
      currentPage.current = 1;
      generateItems();
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("usersMQTTChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSensors = async (event?: any) => {
    const respuesta = await axios.get(
      url + `sensor/usersmqtt?limit=20&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
      hasNextPage.current = respuesta.data.hasNextPage;
    }
    setItems([...items, ...respuesta.data.docs]);
    setLoading(false);
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSensors(event);
      } else {
        getSensors();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const editUser = (item: UserMqtt) => {
    history.push(`/page/EditMosquitoUser?user=${encodeB64(item)}`);
  };
  if (loading) {
    return (
      <IonContent className={styles.mosquitoUsersListContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.mosquitoUsersListContent}>
        {items.map((item, index) => (
          <IonRow key={index} className={styles.mosquitoUsersListRow}>
            <IonCol size="5">
              <IonText>{item.username}</IonText>
            </IonCol>
            <IonCol size="5">
              <IonText>{item.passwordPlain}</IonText>
            </IonCol>
            <IonCol>
              <IonIcon
                className={styles.mosquitoUsersListIcon}
                icon="assets/icons/logo-config.svg"
                onClick={() => editUser(item)}
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="100%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    );
  }
};

export default MosquitoUsersList;
