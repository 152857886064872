/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import {
  IonButton,
  IonButtons,
  IonGrid,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  isPlatform,
} from "@ionic/react";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthInfoContext from "../../../../context/authinfo-context";
import { Sensor } from "../../../../context/interfaces-context";
import styles from "./Fota.module.css";

const Fota: React.FC = () => {
  const { url, token } = useContext(AuthInfoContext);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [firmwares, setFirmwares] = useState<{ _id: string; name: string }[]>(
    []
  );
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const idSensor = useRef("");
  const idFirmware = useRef("");
  const iFrame = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSensors = async (event?: any) => {
    const respuesta = await axios.get(
      url + `sensor?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setSensors([...sensors, ...respuesta.data.docs]);
    if (event) {
      event.target.complete();
    }
  };
  const getFirmwares = async (event?: any) => {
    const respuesta = await axios.get(
      url + `firmware?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setFirmwares([...firmwares, ...respuesta.data.docs]);
    if (event) {
      event.target.complete();
    }
  };
  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSensors(event);
        getFirmwares(event);
      } else {
        getSensors();
        getFirmwares();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const updateFirmware = () => {
    console.log('FOTA')
    iFrame.current?.setAttribute(
      "src",
      `https://terminal.sunandplayapp.duckdns.org/?arg=${idSensor.current}&arg=fota&arg=${token}&arg=${idFirmware.current}`
    );
    iFrame.current?.removeAttribute("hidden");
  };
  return (
    <IonGrid style={{ height: "80%" }}>
      <IonTitle>Fota</IonTitle>
      <IonList style={{ minWidth: "100%" }}>
        <IonItem style={{ minWidth: "100%" }}>
          <IonSelect
            interface="popover"
            placeholder="Seleccionar Sensor"
            className={styles.sensorselectSelect}
            onIonChange={(e) => (idSensor.current = e.target.value)}
          >
            {sensors.map((sensor, index) => (
              <IonSelectOption
                value={sensor._id}
                key={index}
                className=""
                style={{ minWidth: "100%" }}
              >
                {sensor._id + " - " + sensor.code}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>
      <IonList style={{ minWidth: "100%" }}>
        <IonItem style={{ minWidth: "100%" }}>
          <IonSelect
            interface="popover"
            placeholder="Seleccionar Firmware"
            className={styles.sensorselectSelect}
            onIonChange={(e) => (idFirmware.current = e.target.value)}
          >
            {firmwares.map((firmware, index) => (
              <IonSelectOption
                value={firmware._id}
                key={index}
                className=""
                style={{ minWidth: "100%" }}
              >
                {firmware.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>
      <IonButtons>
        <IonButton onClick={updateFirmware}>Actualizar firmware</IonButton>
      </IonButtons>
      {/*
          <Xterm
            ref={terminal}
            options={options}
            addons={[searchAddon, attachAddon]}
          />*/}
      <iframe
        ref={iFrame}
        hidden
        style={{ width: "100%", height: "95%" }}
        allowFullScreen
        frameBorder={0}
      />
    </IonGrid>
  );
};

export default Fota;
