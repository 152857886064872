import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonIcon,
  IonText,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useContext } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import { useHistory } from "react-router";
import styles from "./Login.module.css";
import axios from "axios";
import { useRef } from "react";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import ChangePasswordModal from "../../components/Modals/ChangePassword/ChangePasswordModal";
import RequestEmail from "../../components/Modals/ChangePassword/RequestEmail";

const Login: React.FC = () => {
  const { presentToast, setToken, url, logIn, getMyUser } =
    useContext(AuthInfoContext);
  const email = useRef<string | undefined>("");
  const password = useRef<string | undefined>("");
  const changePasswordmodal = useRef<HTMLIonModalElement>(null);
  const emailModal = useRef<HTMLIonModalElement>(null);
  const history = useHistory();

  const submitLogin = () => {
    if (email.current && password.current) {
      loginUser({ email: email.current, password: password.current });
    }else{
      presentToast('Ingrese los datos para poder iniciar sesion', alertCircleOutline, "danger", "top");
    }
  };

  const backToWelcome = () => {
    history.replace('/Main');
  };
  
  //Funcion de inicio de sesion de usuario
  const loginUser = async (data: { email: string; password: string }) => {
    const respuesta = await axios.post(url + "auth/login", data, {
      validateStatus: function (status) {
        return status < 500;
      },
    });
    if (respuesta.data.token) {
      presentToast(
        respuesta.data.message,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      setToken(respuesta.data.token);
      logIn(respuesta.data.token);
      getMyUser(respuesta.data.token);
      history.replace("/Main");
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };
  /*
  const validEmail = (email: any) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };*/
  
  //Funcion que cierra todos los modales al salir de la vista
  useIonViewDidLeave(()=>{
    emailModal.current?.dismiss()
    changePasswordmodal.current?.dismiss()
  })

  //Funcion que despliega el modal que solicitara un email para recuperar la contraseña
  const sendEmail = () => {
    emailModal.current?.present()
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.loginButtons}>
            <IonButton shape="round" onClick={backToWelcome}>
              <IonIcon
                className={styles.loginIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className={styles.loginContainer}>
          <IonTitle className={styles.loginTitle} size="large">
            SUN<span>AND</span>PLAY
          </IonTitle>
          <IonRow className={styles.loginRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.loginInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Tu email
                </IonLabel>
                <IonInput
                  placeholder="Tu email"
                  type="email"
                  onIonChange={(e) =>
                    (email.current = e.target.value?.toString())
                  }
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submitLogin();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.loginRow}>
            <IonCol className={styles.loginCol}>
              <IonItem className={styles.loginInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Contraseña
                </IonLabel>
                <IonInput
                  placeholder="Contraseña"
                  type="password"
                  onIonChange={(e) =>
                    (password.current = e.target.value?.toString())
                  }
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submitLogin();
                    }
                  }}
                />
              </IonItem>
              <IonGrid className={styles.loginLinkContainer}>
                <IonText className={styles.loginLink} onClick={sendEmail}>
                  Recuperar contraseña
                </IonText>
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.loginButton}
                expand="block"
                fill="outline"
                onClick={submitLogin}
              >
                Entrar
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <RequestEmail modal={emailModal} changePasswordModal={changePasswordmodal} email={email}/>
      <ChangePasswordModal modal={changePasswordmodal} email={email} />
    </IonPage>
  );
};

export default Login;
