import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { GoogleMap } from "@capacitor/google-maps";
import { useRef } from "react";
import React from "react";
import styles from "./Map.module.css";
import { Geolocation } from "@capacitor/geolocation";
import { markers } from "../../data/markers";
const Map: React.FC = () => {
  const mapRef = useRef<HTMLElement>();
  let newMap: GoogleMap;
  let currentPosition: any[] = [];

  const getCurrentPosition = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    currentPosition = [
      coordinates.coords.latitude,
      coordinates.coords.longitude,
    ];
    console.log(coordinates.coords.latitude, coordinates.coords.longitude);
    await createMap();
  };
  const addMapMarker = async (marker: {
    lat: any;
    lng: any;
    title: any;
    iconUrl: string;
  }) => {
    await newMap.addMarker({
      coordinate: {
        lat: marker.lat,
        lng: marker.lng,
      },
      title: marker.title,
      iconUrl: marker.iconUrl,
      iconSize: { width: 50, height: 50 },
    });
  };

  const enableCurrentLocation = async () => {
    await newMap.enableCurrentLocation(true);
  };

  const addMapMarkers = () => markers.forEach((marker) => addMapMarker(marker));
  const createMap = async () => {
    if (!mapRef.current) return;
    newMap = await GoogleMap.create({
      id: "google-map",
      element: mapRef.current,
      apiKey: "AIzaSyDhZ61fx2sPAo9ZpwB1BP9mZXIa2vMDlrg",
      config: {
        center: {
          lat: currentPosition[0],
          lng: currentPosition[1],
        },
        zoom: 14,
      },
    });
    addMapMarkers();
    enableCurrentLocation();
  };
  useIonViewWillEnter(() => getCurrentPosition());
  //  useIonViewWillEnter(() => createMap());
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Google Maps</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className={styles.container}>
        <capacitor-google-map
          ref={mapRef}
          style={{ display: "inline-block", width: "100%", height: "100%" }}
        ></capacitor-google-map>
      </div>
    </IonPage>
  );
};

/*
  const key = "AIzaSyDhZ61fx2sPAo9ZpwB1BP9mZXIa2vMDlrg";
  let newMap: GoogleMap;
  const mapRef = useRef<HTMLElement>(null);
  const [mapConfig, setMapConfig] = useState({
    zoom: 12,
    center: {
      lat: -33.4528512,
      lng: -70.6347008 ,
    },
  });

  const createMap = async () => {
    if (!mapRef.current) return;

    newMap = await GoogleMap.create({
      id: "google-map",
      element: mapRef.current,
      apiKey: key,
      config: mapConfig,
    });
  };
  useIonViewWillEnter(() => createMap());
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Capacitor Google Maps</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Capacitor Google Maps</IonTitle>
          </IonToolbar>
        </IonHeader>
        <capacitor-google-map
          ref={mapRef}
          style={{ display: "inline-block", width: 275, height: 400 }}
        ></capacitor-google-map>
      </IonContent>
    </IonPage>
  );
};
*/
export default Map;
