import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext } from "react";
import styles from "./AddSensorCode.module.css";
import CodeNumber from "../../../components/CodeNumber/CodeNumber";
import { useHistory } from "react-router";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";

const AddSensorCode: React.FC = () => {
  const { url, token, presentToast, setCode } = useContext(AuthInfoContext);
  const history = useHistory();
  const backTo = () => {
    history.goBack();
  };
  const sendCodeSensor = async (code: string) => {
    const respuesta = await axios.post(
      url + "sensor/validateotp",
      { code: code },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.authorized) {
      presentToast(respuesta.data.message, checkmarkCircleOutline, "success",'top');
      setCode(code);
      history.replace("/page/AddSensorConfig");
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger",'top');
    }
  };
  const handlerCode = (code: string) => {
    if (code.length < 8) {
      presentToast('Ingresa un codigo valido para continuar', alertCircleOutline, "danger",'top',1500);
    } else {
      sendCodeSensor(code);
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className={styles.sensorcodeToolBar}>
          <IonButtons slot="start" className={styles.sensorcodeButtons}>
            <IonButton 
              shape="round" 
              className={styles.sensorcodeButtonIcon}
              onClick={backTo}
            > 
              <IonIcon
                className={styles.sensorcodeIcon}
                icon="assets/icons/logo-vector.svg"
              />
              <IonText className={styles.sensorcodeIconText}>
                Menú Principal
              </IonText>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>


      <IonContent
        fullscreen
        className={
          styles.sensorcodeContenedor +
          " ion-display-flex ion-text-center ion-justify-content-center ion-align-items-center "
        }
      >
        <IonGrid className={styles.sensorcodeContainer}>
          <IonTitle className={styles.sensorcodeTitle}>Agregar Sensor</IonTitle>
          <IonText className={styles.sensorcodeText}>El código identificador lo encuentras en la tapa de cada sensor, revisa bien para evitar errores.</IonText>
          <CodeNumber length={8} sendCode={handlerCode} />
          <div className={styles.bottommargin}> </div>
        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default AddSensorCode;
