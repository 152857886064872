import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToggle,
  isPlatform
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./CodeUserModal.module.css";
import AuthInfoContext from "../../../context/authinfo-context";
import DisplayCodeModal from "./DisplayCodeModal";



const CodeUserModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  id?: string;
}> = (props) => {
  const { socket, token } = useContext(AuthInfoContext);
  const modal = props.modal;
  const modalToggleAdmin = useRef<HTMLIonToggleElement>(null);
  const modalToggleOperator = useRef<HTMLIonToggleElement>(null);
  const modalCode = useRef<HTMLIonModalElement>(null);
  const userType = useRef<string>("");
  const [userCode, setUserCode] = useState<string>("");
  const AcceptButton = useRef<HTMLIonButtonElement>();


  const changeToggle = (toggle: string) => {
    switch (toggle) {
      case "Admin":
        if (modalToggleAdmin.current?.checked) {
          if (modalToggleOperator.current) {
            modalToggleOperator.current.checked = false;
            userType.current = "Administrator";
            AcceptButton.current?.removeAttribute("disabled");
          }
          else{AcceptButton.current?.setAttribute("disabled", "");}
          
        }
        else{AcceptButton.current?.setAttribute("disabled", "");}
        break;
      case "Operator":
        if (modalToggleOperator.current?.checked) {
          if (modalToggleAdmin.current) {
            modalToggleAdmin.current.checked = false;
            userType.current = "Operator";
            AcceptButton.current?.removeAttribute("disabled");
          }
          else{AcceptButton.current?.setAttribute("disabled", "");}
        }
        else{AcceptButton.current?.setAttribute("disabled", "");}
        break;
      default:
          AcceptButton.current?.setAttribute("disabled", "");
        break;
    }
  };
  const generateCode = () => {
    if (userType.current !== "") {
      if (props.id) {
        socket?.emit("organizationOtpGenerate", {
          token: token,
          role: userType.current,
          idOrganization: props.id,
        });
      } else {
        socket?.emit("organizationOtpGenerate", {
          token: token,
          role: userType.current,
        });
      }
    }
  };
  useEffect(() => {
    socket?.on("organizationOtpGenerated", (msg) => {
      setUserCode(msg);
      modalCode.current?.present();
    });
    return () => {
      socket?.off("organizationOtpGenerated");
    };
  });
  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.8;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }
  return (
    <IonModal
      ref={modal}
      initialBreakpoint={ isPlatform("desktop") ? 0.45 : calculateHeigthOfModal()}
      breakpoints={isPlatform("desktop") ? [0,0.45] : [0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        e.target.dismiss();
      }}
      className={styles.codeusermodalModal}
    >
      <IonContent className="ion-justify-content-center">
        <div className={styles.mainContainer}>
          <IonGrid className={styles.codeusermodalItemIcon}>
            <IonIcon
              icon="assets/icons/logo-add-user.svg"
              className={styles.codeusermodalIcon}
            />
          </IonGrid>
          <IonGrid className={styles.codeusermodalItemTitle}>
            <IonText className={styles.codeusermodalTitle}>
              Crear código para añadir perfil
            </IonText>
          </IonGrid>
          <IonGrid className={styles.codeusermodalItem}>
            <IonText className={styles.codeusermodalText}>Administrador</IonText>
            <IonToggle
              ref={modalToggleAdmin}
              onIonChange={() => changeToggle("Admin")}
              className={styles.codeusermodalToggle}
            ></IonToggle>
            <IonText className={styles.codeusermodalText}>Operario</IonText>
            <IonToggle
              ref={modalToggleOperator}
              onIonChange={() => changeToggle("Operator")}
              className={styles.codeusermodalToggle}
            ></IonToggle>
          </IonGrid>
          <IonGrid className={styles.codeusermodalItemButton}>
            <IonButton
              className={styles.codeusermodalButton}
              onClick={() => generateCode()}
              expand="block"
              fill="outline"
              disabled={true}
              ref={(el: HTMLIonButtonElement) => (AcceptButton.current = el)}
            >
              <IonItem className={styles.codeusermodalItemButtonText}>
                ACEPTAR
              </IonItem>
            </IonButton>
          </IonGrid>
        </div>
      </IonContent>
      <DisplayCodeModal parentModal={modal} modal={modalCode} code={userCode} />
    </IonModal>
  );
};

export default CodeUserModal;
