import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useContext} from "react";
import styles from "./RequestEmail.module.css";
import axios from "axios";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";
import AuthInfoContext from "../../../context/authinfo-context";
const RequestEmail: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  changePasswordModal: React.RefObject<HTMLIonModalElement>;
  email: React.MutableRefObject<string | undefined>;
}> = ({ modal, changePasswordModal, email }) => {
  const { presentToast, url } = useContext(AuthInfoContext);
  const requestEmail = async () => {
    await axios
      .post(
        url + "auth/forgotpasswordemail",
        { email: email.current },
        {
          validateStatus: function (status) {
            return status < 500;
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          presentToast(
            res.data.message,
            checkmarkCircleOutline,
            "success",
            "top"
          );
          modal.current?.dismiss();
          changePasswordModal.current?.present();
        } else {
          presentToast(res.data.message, alertCircleOutline, "danger", "top");
        }
      })
      .catch((res) => console.log(res.message));
  };
  const validEmail = (email: any) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  const sendEmail = () => {
    if (validEmail(email.current)) {
      requestEmail();
    } else {
      presentToast(
        "Ingresa un correo valido para poder recuperar tu contraseña",
        alertCircleOutline,
        "danger",
        "top",
        2000
      );
    }
  };
  return (
    <IonModal ref={modal} className={styles.requestEmailModal}>
      <IonGrid className={styles.requestEmailContainer}>
        <IonRow className={styles.requestEmailRow + ' ion-justify-content-center ion-no-padding'}>
          <IonText className={styles.emailTittle + " ion-no-padding"}>
            Ingresa el Email de tu cuenta
          </IonText>
        </IonRow>
        <IonRow className={styles.requestEmailRow}>
          <IonItem className={styles.loginInput}>
            <IonLabel position="floating" color="dark">
              Tu email
            </IonLabel>
            <IonInput
              placeholder="Tu email"
              type="email"
              onIonChange={(e) => (email.current = e.target.value?.toString())}
            />
          </IonItem>
        </IonRow>
        <IonRow className={styles.requestEmailRowButton}>
          <IonCol size="5.5">
            <IonButton
              className={styles.requestEmailButton}
              expand="block"
              fill="outline"
              onClick={sendEmail}
            >
              ACEPTAR
            </IonButton>
          </IonCol>
          <IonCol size="5.5">
            <IonButton
              className={styles.requestEmailButton}
              expand="block"
              fill="outline"
              onClick={() => modal.current?.dismiss()}
            >
              CANCELAR
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default RequestEmail;
