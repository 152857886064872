import React from "react";
import {
  ClientToServerEvents,
  ServerToClientEvents,
  User,
} from "./interfaces-context";
import { Socket } from "socket.io-client";

export interface AuthInfoContextModel {
  logged: boolean;
  myUser: User;
  url: string;
  token: string;
  code: string;
  socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;
  loadData:boolean
  userRol:string
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setLoadData: React.Dispatch<React.SetStateAction<boolean>>;
  setUserRol: React.Dispatch<React.SetStateAction<string>>;
  logIn: (token: string) => void;
  logOut: () => void;
  getMyUser: (token: string) => void;
  presentToast: (
    message: string,
    icon: string,
    color:
      | "danger"
      | "dark"
      | "light"
      | "medium"
      | "primary"
      | "secondary"
      | "success"
      | "tertiary"
      | "warning"
      | undefined,
    position: "top" | "bottom" | "middle" | undefined,
    duration?: number | undefined
  ) => void;
}
const AuthInfoContext = React.createContext<AuthInfoContextModel>({
  logged: false,
  url: "",
  myUser: {...{roles:[]}} as User,
  token: "",
  code: "",
  socket: "" as any,
  loadData :false,
  userRol:'',
  setLogged: () => {},
  setToken: () => {},
  setCode: () => {},
  setLoadData:()=>{},
  setUserRol:()=>{},
  logIn: () => {},
  logOut: () => {},
  getMyUser: () => {},
  presentToast: () => {},
});

export default AuthInfoContext;
