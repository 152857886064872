import React, { useContext, useRef, useState } from "react";
import styles from "./AddSensorConfig.module.css";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router";
import axios from "axios";
import {
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import AuthInfoContext from "../../../context/authinfo-context";
import { Geolocation } from "@capacitor/geolocation";
import { Splice } from "../../../context/interfaces-context";

const data: Splice = {
  rated_voltage: "220",
  phone_company: "",
};

const AddSensorConfig: React.FC = () => {
  const { token, code, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory();
  const submitButton = useRef<HTMLIonButtonElement>();
  const ruta = useRef<string | undefined>("");
  const sentidoRuta = useRef<string | undefined>("");
  const estructura = useRef<string | undefined>("");
  const nombreDispositivo = useRef<string | undefined>("");
  const kilometro = useRef<string | undefined>("");
  const coordenadasGPS = useRef<string | undefined>("");
  const numeroLuminarias = useRef<string | undefined>("");
  const [luminariasMixtas, setLuminariasMixtas] = useState<boolean | undefined>(
    false
  );
  const corrienteNominal = useRef<string | undefined>("");
  const corrienteMinima = useRef<string | undefined>("");
  const compañia = useRef<string | undefined>("");
  const telefonoCompañia = useRef<string | undefined>("");
  const [currentPosition, setCurrentPosition] = useState<string>("");

  const backTo = () => {
    history.replace("/page/AddSensorCode");
  };

  const checkValues = () => {
    if (
      ruta.current &&
      sentidoRuta.current &&
      estructura.current &&
      nombreDispositivo.current &&
      kilometro.current &&
      coordenadasGPS.current &&
      numeroLuminarias.current &&
      corrienteNominal.current &&
      compañia.current
    ) {
      submitButton.current?.removeAttribute("disabled");
      /*console.log(ruta.current);
      console.log(sentidoRuta.current);
      console.log(nombreDispositivo.current);
      console.log(kilometro.current);
      console.log(coordenadasGPS.current);
      console.log(numeroLuminarias.current);
      console.log(corrienteNominal.current);
      console.log(compañia.current);*/
    }
  };

  const addsensorconfigUser = async (dataSplice: Splice) => {
    console.log({ ...data, ...{ code: code }, ...dataSplice });
    const respuesta = await axios.post(
      url + "splice/myorganization",
      { ...data, ...{ code: code }, ...dataSplice },
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.status === 201) {
      presentToast(
        respuesta.statusText,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      history.replace("/Main");
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };

  const submit = () => {
    let dataCurrent: Splice = {};
    let coords = coordenadasGPS.current?.split(",");
    if (
      ruta.current &&
      sentidoRuta.current &&
      estructura.current &&
      nombreDispositivo.current &&
      kilometro.current &&
      coordenadasGPS.current &&
      numeroLuminarias.current &&
      corrienteNominal.current &&
      compañia.current &&
      coords &&
      code
    ) {
      if (luminariasMixtas) {
        dataCurrent = {
          stretch: ruta.current,
          side: sentidoRuta.current,
          infrastructure: estructura.current,
          name: nombreDispositivo.current,
          pk: kilometro.current,
          number_of_luminaries: numeroLuminarias.current,
          rated_current: corrienteNominal.current,
          latitude: coords[0],
          longitude: coords[1],
          mixed_luminaire: luminariasMixtas,
          minimum_current: corrienteMinima.current,
          company: compañia.current,
          phone_company: telefonoCompañia.current,
        };
      } else {
        dataCurrent = {
          stretch: ruta.current,
          side: sentidoRuta.current,
          infrastructure: estructura.current,
          name: nombreDispositivo.current,
          pk: kilometro.current,
          latitude: coords[0],
          longitude: coords[1],
          number_of_luminaries: numeroLuminarias.current,
          rated_current: corrienteNominal.current,
          mixed_luminaire: luminariasMixtas,
          minimum_current: parseFloat(
            (
              parseFloat(corrienteNominal.current.replace(",",".")) /
              parseInt(numeroLuminarias.current)
            ).toString()
          ).toFixed(3),
          company: compañia.current,
          phone_company: telefonoCompañia.current,
        };
      }
      addsensorconfigUser(dataCurrent);
    } else {
    }
  };

  const setCurrentLocation = async () => {
    setCurrentPosition("Obteniendo coordenadas...");
    const coordinates = await Geolocation.getCurrentPosition();
    setCurrentPosition(
      coordinates.coords.latitude + " , " + coordinates.coords.longitude
    );
  };
  
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className={styles.addsensorconfigToolBar}>
          <IonButtons slot="start" className={styles.addsensorconfigButtons}>
            <IonButton
              shape="round"
              className={styles.addsensorconfigButtonIcon}
              onClick={backTo}
            >
              <IonIcon
                className={styles.addsensorconfigIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>


      <IonContent>
        <IonGrid className={styles.addsensorconfigContainer}>
          <IonTitle className={styles.addsensorconfigTitle} size="large">
            CONFIGURACIÓN
          </IonTitle>

          <IonRow className={styles.addsensorconfigRow}>
            <IonItem
              className={styles.addsensorconfigInputLuminaries}
              lines="none"
            >
              <IonCol className="ion-text-center" size="5.75">
                <IonItem lines="none">
                  <IonLabel position="floating" color="dark">
                    <div className={styles.addsensorconfigInputText}>
                      Ruta asociada
                    </div>
                  </IonLabel>
                  <IonInput
                    color="dark"
                    placeholder="Ruta asociada"
                    onIonChange={(e) => {
                      ruta.current = e.target.value?.toString();
                      checkValues();
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="ion-text-center">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    <div className={styles.addsensorconfigInputText}>
                      Sentido
                    </div>
                  </IonLabel>
                  <IonSelect
                    className={styles.addsensorconfigSelect}
                    placeholder="Seleccionar"
                    onIonChange={(e) => {
                      sentidoRuta.current = e.target.value?.toString();
                      checkValues();
                      
                    }}
                  >
                    <IonSelectOption value={"Norte"}>Norte</IonSelectOption>
                    <IonSelectOption value={"Sur"}>Sur</IonSelectOption>
                    <IonSelectOption value={"Este"}>Este</IonSelectOption>
                    <IonSelectOption value={"Oeste"}>Oeste</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonItem>
          </IonRow>

          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                    Tipo de estructura
                  </div>
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Tipo de estructura"
                  onIonChange={(e) => {
                    estructura.current = e.target.value?.toString();
                    checkValues();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                    Nombre del dispositivo
                  </div>
                </IonLabel>
                <IonInput
                  
                  color="dark"
                  placeholder="Nombre del dispositivo"
                  onIonChange={(e) => {
                    nombreDispositivo.current = e.target.value?.toString();
                    checkValues();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                <div className={styles.addsensorconfigInputText}>
                    Kilometro asociado
                </div>
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Kilometro asociado"
                  onIonChange={(e) => {
                    kilometro.current = e.target.value?.toString();
                    checkValues();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.addsensorconfigRowGPS}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInputGPS} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                    Coordenadas GPS
                  </div>
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Coordenadas GPS"
                  value={currentPosition}
                  onIonChange={(e) => {
                    coordenadasGPS.current = e.target.value?.toString();
                    checkValues();
                  }}
                />
              </IonItem>
              <IonIcon
                className={styles.addsensorconfigIconGPS}
                icon='assets/icons/gps-icon.svg'
                onClick={setCurrentLocation}
              />
            </IonCol>
          </IonRow>

          <IonRow className={styles.addsensorconfigRow}>
            <IonItem
              className={styles.addsensorconfigInputLuminaries}
              lines="none"
            >
              <IonCol className="ion-text-center" size="5.75">
                <IonItem lines="none">
                  <IonLabel position="floating" color="dark">
                    <div className={styles.addsensorconfigInputText}>
                      Nº de luminarias
                    </div>
                  </IonLabel>
                  <IonInput
                  className={styles.addsensorconfigSelect}
                    color="dark"
                    placeholder="Nº de luminarias"
                    onIonChange={(e) => {
                      numeroLuminarias.current = e.target.value?.toString();
                      checkValues();
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="ion-text-center">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    <div className={styles.addsensorconfigInputText}>
                      Tipo de Empalme
                    </div>
                  </IonLabel>
                  <IonSelect
                    placeholder="Ninguno"
                    
                    value={luminariasMixtas}
                    onIonChange={(e) => setLuminariasMixtas(e.target.value)}
                  >
                    <IonSelectOption value={false}>Uniforme</IonSelectOption>
                    <IonSelectOption value={true}>Mixta</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonItem>
          </IonRow>

          {luminariasMixtas ? (
            <IonRow className={styles.addsensorconfigRow}>
              <IonCol className="ion-text-center">
                <IonItem className={styles.addsensorconfigInput} lines="none">
                  <IonLabel position="floating" color="dark">
                    <div className={styles.addsensorconfigInputText}>
                      Corriente minima de consumo
                    </div>
                  </IonLabel>
                  <IonInput
                    placeholder="Corriente minima de consumo"
                    onIonChange={(e) => {
                      checkValues();
                      corrienteMinima.current = e.target.value?.toString().replace(',','.');
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          ) : (
            <></>
          )}

          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                      Corriente nominal medida
                  </div>
                </IonLabel>
                <IonInput
                  placeholder="Corriente nominal medida"
                  onIonChange={(e) => {
                    checkValues();
                    corrienteNominal.current =  e.target.value?.toString().replace(',','.');
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                    Empresa eléctrica
                  </div>
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Empresa eléctrica"
                  onIonChange={(e) => {
                    checkValues();
                    compañia.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.addsensorconfigRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.addsensorconfigInput} lines="none">
                <IonLabel position="floating" color="dark">
                  <div className={styles.addsensorconfigInputText}>
                    Nº de atencion al cliente
                  </div>
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder=" Nº de atencion al cliente"
                  inputMode="numeric"
                  type="tel"
                  maxlength={13}
                  onIonChange={(e) => {
                    checkValues();
                    telefonoCompañia.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.addsensorconfigButton}
                expand="block"
                fill="outline"
                disabled
                onClick={submit}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AddSensorConfig;
