/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import {
  IonButton,
  IonButtons,
  IonGrid,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  isPlatform,
} from "@ionic/react";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthInfoContext from "../../../../context/authinfo-context";
import { Sensor } from "../../../../context/interfaces-context";
import styles from "./Debug.module.css";

const Debug: React.FC = () => {
  const { url, token } = useContext(AuthInfoContext);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const idSensor = useRef("");
  const iFrame = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSensors = async (event?: any) => {
    const respuesta = await axios.get(
      url + `sensor?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setSensors([...sensors, ...respuesta.data.docs]);
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSensors(event);
      } else {
        getSensors();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const debugSensor = ()=>{
    iFrame.current?.setAttribute('src',`https://terminal.sunandplayapp.duckdns.org/?arg=${idSensor.current}&arg=debug&arg=${token}`)
    iFrame.current?.removeAttribute('hidden')
  }
  return (
        <IonGrid style={{ height: "80%" }}>
          <IonTitle>Debug</IonTitle>
          <IonList style={{ minWidth: "100%" }}>
            <IonItem style={{ minWidth: "100%" }}>
              <IonSelect
                interface="popover"
                placeholder="Seleccionar Sensor"
                className={styles.sensorselectSelect}
                onIonChange={(e) => (idSensor.current = e.target.value)}
              >
                <IonSelectOption
                    value={0}
                    key={-1}
                    className=""
                    style={{ minWidth: "100%" }}
                  >
                     Admin
                  </IonSelectOption>
                {sensors.map((sensor, index) => (
                  <IonSelectOption
                    value={sensor._id}
                    key={index}
                    className=""
                    style={{ minWidth: "100%" }}
                  >
                     {sensor._id +' - '+sensor.code}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
          <IonButtons>
            <IonButton onClick={debugSensor}>Debug Sensor</IonButton>
          </IonButtons>
          {/*
          <Xterm
            ref={terminal}
            options={options}
            addons={[searchAddon, attachAddon]}
          />*/}
            <iframe
              ref={iFrame}
              hidden
              style={{ width: "100%", height: "95%" }}
              allowFullScreen
              frameBorder={0}
            />

        </IonGrid>
  );
};

export default Debug;
