import {
  IonButton,
  IonGrid,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import styles from "./SignInModal.module.css";
import { useHistory } from "react-router";
const SignInModal: React.FC<{ modal: React.RefObject<HTMLIonModalElement> }> = (
  props
) => {
  const history = useHistory()
  const redirectToLogin = ()=>{
    props.modal.current?.dismiss()
    history.replace('/Login')
  }
  return (
    <IonModal ref={props.modal} className={styles.modalModal}>
      <IonGrid className={styles.modalContainer}>
        <IonRow className={styles.modalRow}>
          <IonText className={styles.modalText}>
          Su cuenta a sido registrada con exito,
          para continuar con su inicio de sesión seleccione aceptar 
          </IonText>
        </IonRow>
        <IonRow className={styles.modalRow}>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={redirectToLogin}
          >
            ACEPTAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default SignInModal;
