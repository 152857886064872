import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef } from "react";
import styles from "./ChangePassword.module.css";
import { useHistory } from "react-router";
import { IonInput } from "@ionic/react";
import axios from "axios";
import AuthInfoContext from "../../context/authinfo-context";
import { checkmarkCircleOutline, alertCircleOutline } from 'ionicons/icons';

const ChangePassword: React.FC = () => {
  const { url, presentToast } = useContext(AuthInfoContext);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const history = useHistory();
  const noCoincidencenPassNote = useRef<HTMLIonNoteElement>();
  const submitButton = useRef<HTMLIonButtonElement>();
  const password = useRef<string | undefined>("");
  const passwordConfirm = useRef<string | undefined>("");

  const backTo = () => {
    history.replace("/Main");
  };

  const confirmPassword = () => {
    if (password.current === passwordConfirm.current) {
      noCoincidencenPassNote.current?.setAttribute("hidden", "");
      checkValues();
    } else {
      noCoincidencenPassNote.current?.removeAttribute("hidden");
      submitButton.current?.setAttribute("disabled", "");
    }
  };

  const checkValues = () => {
    if (
      password.current &&
      passwordConfirm.current &&
      password.current === passwordConfirm.current
    ) {
      submitButton.current?.removeAttribute("disabled");
    }
  };

  const submit = () => {
    if (password.current) {
      sendPassword(password.current);
    } else {
    }
  };

  const sendPassword = async (password: string) => {
    await axios
      .post(
        url + "auth/forgotpassword",
        { password: password },
        {
          validateStatus: function (status) {
            return status < 500;
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if(res.status === 201){
          presentToast(res.data.message,checkmarkCircleOutline,'success','top')
          history.replace('/Login')
        }else{
          presentToast('Error: Vuelva a solicitar la recuperacion de su contraseña',alertCircleOutline,'danger','top',2000)
        }
      })
      .catch((res) => console.log(res));
  };
  
  useEffect(() => {
    console.log(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.passwordButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.passwordIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.passwordContainer}>
        <IonGrid className={styles.passwordGrid}>
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-justify-content-center">
              <IonText className={styles.passwordTitle}>
                Crea una contraseña segura{" "}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className={styles.passwordText}>
                La contraseña debe tener al menos seis caracteres e incluir una
                combinación de números, letras y caracteres especiales{" "}
                {"(!$@%)"}.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className={styles.passwordRowInput + " ion-no-padding"}>
            <IonCol className={styles.passwordColInput + " ion-no-padding"}>
              <IonItem className={styles.passwordInput + " ion-no-padding"}>
                <IonLabel position="floating" color="dark">
                  Contraseña Nueva
                </IonLabel>
                <IonInput
                  placeholder="Contraseña Nueva"
                  type="password"
                  minlength={6}
                  onIonChange={(e) => {
                    password.current = e.target.value?.toString();
                    confirmPassword();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.passwordRowInput + " ion-no-padding"}>
            <IonCol className={styles.passwordColInput + " ion-no-padding"}>
              <IonItem className={styles.passwordInput + " ion-no-padding"}>
                <IonLabel position="floating" color="dark">
                  Repetir Contraseña Nueva
                </IonLabel>
                <IonInput
                  placeholder="Repetir Contraseña Nueva"
                  type="password"
                  minlength={6}
                  onIonChange={(e) => {
                    passwordConfirm.current = e.target.value?.toString();
                    confirmPassword();
                  }}
                />
                <IonNote
                  slot="error"
                  className={styles.passwordNote}
                  ref={(el: HTMLIonNoteElement) =>
                    (noCoincidencenPassNote.current = el)
                  }
                  hidden
                >
                  Las Contraseñas No Coinciden
                </IonNote>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.passwordRowButton}>
            <IonCol className={styles.passwordColButton}>
              <IonButton
                className={styles.passwordButton}
                expand="block"
                fill="outline"
                onClick={submit}
                disabled
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                RESTABLECER CONTRASEÑA
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
