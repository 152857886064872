import React from "react";

import styles from "./ListLoading.module.css";
import { IonIcon } from "@ionic/react";

const ListLoading: React.FC = () => {
  return (
    <IonIcon icon="assets/icons/loading.svg" className={styles.loading}/>
  );
};

export default ListLoading;
