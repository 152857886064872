import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonTitle,
} from "@ionic/react";
import React from "react";
import styles from "./Welcome.module.css";
import { Link, useHistory } from "react-router-dom";

const Welcome: React.FC = () => {
  const history = useHistory();
  const login = () => {
    history.replace("/Login");
  };
  return (
    <IonPage>
      <IonContent
        fullscreen
      >
        <IonGrid className={styles.welcomeContainer}>
          <IonIcon
            className={styles.welcomeIcon}
            icon="assets/icons/logo-sunandplay.svg"
          />
          <IonTitle className={styles.welcomeTitle}>SUN<span>AND</span>PLAY</IonTitle>
          <IonButton className={styles.welcomeButton} onClick={() => login()}>
            Entrar
          </IonButton>
          <Link to="/CodeSignIn" className={styles.welcomeLink}>
            Crear Cuenta
          </Link>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
