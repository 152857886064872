import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useRef } from "react";
import styles from "./AdminOrganizationUsers.module.css";
import { useHistory } from "react-router";
import OrganizationUsersList from "../../../../components/Lists/SuperAdmin/UsersList/OrganizationUsersList";
import { addCircleOutline } from "ionicons/icons";
import CodeUserModal from "../../../../components/Modals/Users/CodeUserModal";

const AdminOrganizationUsers: React.FC = () => {
  const history = useHistory();
  const backTo = () => {
    history.replace("/Main");
  };
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const codeUserModal = useRef<HTMLIonModalElement>(null);
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.adminuserBarButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.adminuserBarIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className={styles.homeHeaderButtons}>
            <IonButton
              id="open-modal"
              className={styles.homeButtonIcon}
              shape="round"
              onClick={() => codeUserModal.current?.present()}
            >
              <IonIcon className={styles.homeIcons} icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.adminuserTitleContainer}>
        <IonTitle className={styles.adminuserTitle}>USUARIOS</IonTitle>
        <IonGrid className={styles.adminuserStatesContainer}>
          <IonText className={styles.adminuserStateN}>
            VIGENTE
            <IonIcon
              icon="assets/icons/request-user-verify.svg"
              className={styles.adminuserIconN}
            />
          </IonText>
          <IonText className={styles.adminuserStateA}>
            PENDIENTE
            <IonIcon
              icon="assets/icons/request-user-pending.svg"
              className={styles.adminuserIconN}
            />
          </IonText>
        </IonGrid>
      </IonGrid>
      <IonGrid className={styles.userListContainer}>
        <IonRow className={styles.userListHeader}>
          <IonCol size="5.5">
            <IonText className="ion-vertical-align-middle">Nombre</IonText>
          </IonCol>
          <IonCol size="4">
            <IonText>Tipo de Usuario</IonText>
          </IonCol>
          <IonCol size="2.5">
            <IonText>Estado Solicitud</IonText>
          </IonCol>
        </IonRow>
        <OrganizationUsersList />
      </IonGrid>
      <CodeUserModal modal={codeUserModal} id={id as string} />
    </IonPage>
  );
};

export default AdminOrganizationUsers;
