import React from "react";

import styles from "./Loading.module.css";
import {
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
} from "@ionic/react";


const Loading: React.FC = () => {



  return (
    <IonPage>
      <IonContent fullscreen className={styles.loadingcontainer}>
        <IonGrid className={styles.loadingcontainer}>
          <IonIcon icon="assets/icons/loading.svg" className={styles.loading} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Loading;

