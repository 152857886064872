import * as React from 'react';
import { SpecialZoomLevel,LoadError,Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

interface ToolbarSlotsExampleProps {
    fileUrl: string;
    httpHeaders:any
}

const PdfViewer: React.FC<ToolbarSlotsExampleProps> = ({ fileUrl,httpHeaders }) => {
    //console.log(fileUrl,httpHeaders);
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const renderError = (error: LoadError) => {
      let message = '';
      switch (error.name) {
          case 'InvalidPDFException':
              message = 'No existe un plano asociado';
              break;
          case 'MissingPDFException':
              message = 'The document is missing';
              break;
          case 'UnexpectedResponseException':
              message = 'NO existe un plano asociado';
              break;
          default:
              message = 'No se pudo cargar el archivo';
              if(error.message?.includes('does not match the Worker version')){
                message = 'Actualizar paquete de API';
              }
              break;
      }
  
      return (
          <div
              style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
              }}
          >
              <div
                  style={{
                      backgroundColor: '#e53e3e',
                      borderRadius: '0.25rem',
                      color: '#fff',
                      padding: '0.5rem',
                  }}
              >
                  {message}
              </div>
          </div>
      );
  };

    return (
        <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    justifyContent:'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                }}
            >
                <Toolbar>
                    {(props: ToolbarSlot) => {
                        const {
                            EnterFullScreen,
                            Zoom,
                            ZoomIn,
                            ZoomOut,
                        } = props;
                        return (
                            <>
                                <div style={{ padding: '0px 2px', marginLeft: 'auto'}}>
                                    <ZoomOut />
                                </div>
                                <div style={{ padding: '0px 2px'}}>
                                    <Zoom />
                                </div>
                                <div style={{ padding: '0px 2px'}}>
                                    <ZoomIn />
                                </div>
                                <div style={{ padding: '0px 2px', marginLeft: 'auto',zIndex:'99' }}>
                                    <EnterFullScreen />
                                </div>
                            </>
                        );
                    }}
                </Toolbar>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <Viewer defaultScale={SpecialZoomLevel.PageWidth} fileUrl={fileUrl} httpHeaders={httpHeaders} plugins={[toolbarPluginInstance]} renderError={renderError} />
            </div>
        </div>
    );
};

export default PdfViewer;