import { IonButton, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import React from "react";
import styles from "./ConfirmUserChange.module.css";
const ConfirmUserChange: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  submit : ()=>void
}> = ({ modal , submit}) => {
  return (
    <IonModal ref={modal} className={styles.modalModal}>
      <IonGrid className={styles.modalContainer}>
        <IonRow className={styles.modalRow}>
          <IonText className={styles.modalText}>
          ¿Estás seguro de querer modificar o actualizar tus datos ?{" "}
          </IonText>
        </IonRow>
        <IonRow className={styles.modalRowButton}>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={submit}
          >
            ACEPTAR
          </IonButton>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={() => {
              modal.current?.dismiss();
            }}
          >
            CANCELAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ConfirmUserChange;
