import { Buffer } from "buffer";
export interface User {
  _id?: string;
  names?: string;
  last_names?: string;
  email?: string;
  password?: string;
  phone?: string;
  verify?: boolean;
  roles?: string[];
  code?: string;
  emailNotify?: boolean;
  company?: string;
  organization?:Organization;
}

export interface Splice {
  _id?: string;
  rated_current?: string;
  rated_voltage?: string;
  number_of_luminaries?: string;
  number_of_luminaries_calculated?: string;
  mixed_luminaire?: boolean;
  tolerance?: string;
  idBlueprint?: string;
  idOrganization?: string;
  minimum_current?: string;
  idSensor?: number;
  name?: string;
  side?: string;
  pk?: string;
  infrastructure?: string;
  installed_potency?: string;
  stretch?: string;
  longitude?: string;
  latitude?: string;
  state?: boolean;
  code?: string;
  company?: string;
  phone_company?: string;
  within_the_schedule?: boolean;
  repair?: boolean;
}
export interface Organization {
  _id?: string;
  address?: string;
  code?: string;
  createdAt?: string;
  name?: string;
  updatedAt?: string;
  vat?: string;
  verify?: boolean;
}
export interface Sensor {
  _id?: number;
  serial_number?: string;
  state?: string;
  instalation_date?: string;
  firmware_version?: string;
  hardware_version?: string;
  idSplice?: string;
  coverage?: string;
  mode?: string;
  idOrganization?: string;
  code?: string;
}
export interface SensorState {
  signal: string;
  energy: string;
  lums: string;
}

export interface sensorData {
  power_supply: boolean;
  power_supply_date: string;
  number_of_luminaries_state: boolean;
  number_of_luminaries_state_date: string;
  coverage: number;
  coverage_date: string;
  repair:boolean
  within_the_schedule:boolean;
  within_the_schedule_date:string
}
export interface ServerToClientEvents {
  reconnect: (callback: () => void) => void;
  spliceChanged: (callback: () => void) => void;
  userChanged: (callback: () => void) => void;
  userRolesChanged: (callback: () => void) => void;
  userChangedProfile: (callback: () => void) => void;
  userLogout: (callback: () => void) => void;
  usersMQTTChanged: (callback: () => void) => void;
  organizationOtpGenerated: (msg: string) => void;
  sensorOtpGenerated: (msg: string) => void;
  sensorStateData: (data: sensorData) => void;
  sensorChanged: (callback: () => void) => void;
  organizationChanged: (callback: () => void) => void;
  organizationDataAmount: (msg: {
    usersVerify: number;
    usersTotal: number;
    splice: number;
    sensorReady: number;
    sensorAmount: number;
    idOrganization: string;
  }) => void;
  firmwareChanged: (callback: () => void) => void;
  blueprintChanged: (callback: () => void) => void;
  userNewToken: (msg: string) => void;
  fota: (msg: any) => void;
}

export interface ClientToServerEvents {
  join: (token?: string) => void;
  userRole: (userRole: { id: string; roles: string; verify: boolean }) => void;
  organizationOtpGenerate: (msg: {
    token: string;
    role: string;
    idOrganization?: string;
  }) => void;
  userEmailNotify: (msg: { token: string; emailNotify: boolean }) => void;
  sensorState: (msg: { token: string; idSensor: number }) => void;
  sensorStateJoin: (msg: { token: string; idSensor: number }) => void;
  sensorStateLeave: (msg: { idSensor: number }) => void;
  sensorOtpGenerate: (msg: { token: string; idSensor: number }) => void;
  organizationVerify: (msg: { idOrganization: string; token: string }) => void;
  organizationDataAmountJoin: (msg: {
    idOrganization: string;
    token: string;
  }) => void;
  organizationDataAmountLeave: (msg: {
    idOrganization: string;
    token: string;
  }) => void;
  userNewToken: (msg: string) => void;
  spliceRepairChange: (msg: { idSplice: string; repair: boolean }) => void;
}

export interface InterServerEvents {
  ping: () => void;
}

export interface UserMqtt {
  username: string;
  passwordPlain: string;
  superuser: boolean;
  acls: { topic: string; acc: number }[];
}

export const decodeB64 = (str: string): object =>
  JSON.parse(Buffer.from(str, "base64").toString("binary"));
export const encodeB64 = (obj: object): string =>
  Buffer.from(JSON.stringify(obj), "binary").toString("base64");
