import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import UserList from "../../../components/Lists/UserList/UserList";
import styles from "./AdminUsers.module.css";
import { useHistory } from "react-router";

const AdminUsers: React.FC = () => {
  const history = useHistory();
  const backTo = () => {
    history.replace("/Main");
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className={styles.adminuserToolBar}>
          <IonButtons slot="start" className={styles.adminuserBarButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.adminuserBarIcon}
                icon="assets/icons/logo-vector.svg"
              />
              <IonTitle className={styles.adminuserBarTitle}>
                Menú principal
              </IonTitle>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>


      <IonGrid className={styles.adminuserTitleContainer}>
        <IonTitle className={styles.adminuserTitle}>USUARIOS</IonTitle>
        <IonGrid className={styles.adminuserStatesContainer}>
          <IonText className={styles.adminuserStateN}>
            VIGENTE
            <IonIcon
              icon="assets/icons/request-user-verify.svg"
              className={styles.adminuserIconN}
            />
          </IonText>
          <IonText className={styles.adminuserStateA}>
            PENDIENTE
            <IonIcon
              icon="assets/icons/request-user-pending.svg"
              className={styles.adminuserIconN}
            />
          </IonText>
        </IonGrid>
      </IonGrid>




      <IonGrid className={styles.adminuserListContainer}>
        <IonRow className={styles.adminuserListHeader + " ion-no-padding"}>
          <IonCol size="5.25" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle">Nombre</IonText>
          </IonCol>
          <IonCol size="3" className="ion-no-padding">
            <IonText>Usuario</IonText>
          </IonCol>
          <IonCol size="1.6" className="ion-no-padding">
            <IonIcon icon="assets/icons/logo-delete.svg"/>
          </IonCol>
          <IonCol size="2.1" className="ion-no-padding">
            <IonText>Estado Solicitud</IonText>
          </IonCol>
        </IonRow>
        <UserList />
      </IonGrid>


    </IonPage>
  );
};

export default AdminUsers;
