import {
  IonGrid,
  IonModal,
  IonRow,
} from "@ionic/react";
import React, { useContext } from "react";
import styles from "./SignInCodeModal.module.css";
import CodeNumber from "../../CodeNumber/CodeNumber";
import AuthInfoContext from "../../../context/authinfo-context";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";
const SignInCodeModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  codeVerify: React.MutableRefObject<string>;
  verify: React.MutableRefObject<boolean | undefined>;
}> = (props) => {
  const { presentToast } = useContext(AuthInfoContext);
  const sendCode = (code: string) => {
    if (props.codeVerify.current === code) {
      props.verify.current = true;
      presentToast(
        "Email Verificado",
        checkmarkCircleOutline,
        "success",
        "top"
      );
      props.modal.current?.dismiss();
    } else {
      presentToast("Codigo Incorrecto", alertCircleOutline, "danger", "top");
    }
  };
  return (
    <IonModal
      ref={props.modal}
      className={styles.codeModalModal}
      backdropDismiss={false}
    >
      <IonGrid className={styles.codeModalContainer}>
        <IonRow className={styles.codeModalRow}>
          <CodeNumber length={6} sendCode={sendCode} cancel={true} handlerCancel={() =>props.modal.current?.dismiss() } />
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default SignInCodeModal;
