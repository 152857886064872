// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Debug_sensorselectSelect__su\\+ef{\n  max-width: 100% !important;\n  width: 100% !important;\n  padding-left: 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/SuperAdmin/Terms/Debug/Debug.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".sensorselectSelect{\n  max-width: 100% !important;\n  width: 100% !important;\n  padding-left: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sensorselectSelect": "Debug_sensorselectSelect__su+ef"
};
export default ___CSS_LOADER_EXPORT___;
