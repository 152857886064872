import {
  /*IonButton,
  IonButtons,*/
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
  IonText,
  IonTitle,
  IonMenuButton
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  bulbOutline,
  codeOutline,
  folderOutline,
  /*homeOutline,*/
} from "ionicons/icons";
import "./Menu.css";
import { useContext, useRef, useEffect } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import AlertModal from "../Modals/Alerts/AlertModal";
import CodeUserModal from "../Modals/Users/CodeUserModal";
import { IonGrid } from "@ionic/react";

interface AppPage {
  url: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  /*{
    title: "Estado Real",
    url: "/Main",
    mdIcon: homeOutline,
  },*/
  {
    title: "Mi perfil",
    url: "/page/MyProfile",
    mdIcon: 'assets/icons/icon-myprofile.svg',
  },
  {
    title: "Alertas",
    url: "/Main",
    mdIcon: 'assets/icons/icon-alerts.svg',
  },
  /*
  {
    title: "Planos",
    url: "/page/Blueprints",
    mdIcon: mapOutline,
  },*/
  {
    title: "Agregar perfiles",
    url: "/Main",
    mdIcon: 'assets/icons/icon-addprofile.svg',
  },
  {
    title: "Admi. usuarios",
    url: "/page/AdminUsers",
    mdIcon: 'assets/icons/icon-adminusers.svg',
  },
  {
    title: "Sensores",
    url: "/page/AllSensors",
    mdIcon: bulbOutline,
  },
  {
    title: "Archivos de firmware",
    url: "/page/FirmwareFiles",
    mdIcon: folderOutline,
  },
  {
    title: "Terminales",
    url: "/page/Terms",
    mdIcon: codeOutline,
  },
  {
    title: "Usuarios Mosquito",
    url: "/page/MosquitoUsers",
    mdIcon: 'assets/icons/icon-myprofile.svg',
  },
  {
    title: "Cerrar Sesion",
    url: "/",
    mdIcon: 'assets/icons/icon-logout.svg',
  },
];

const Menu: React.FC<{ swipe: boolean; hidden?: boolean }> = (props) => {
  const location = useLocation();
  const { logOut, myUser, userRol } = useContext(AuthInfoContext);
  const alertModal = useRef<HTMLIonModalElement>(null);
  const codeUserModal = useRef<HTMLIonModalElement>(null);
  const menuRef = useRef<HTMLIonMenuElement>(null);
  useEffect(() => {
    return () => {
      document.addEventListener("logout", () => {
        console.log("cerrando");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        menuRef.current?.setAttribute("disable", "true");
      });
    };
  }, []);
  return userRol === "SuperAdministrator" ? (
    <IonMenu
      contentId="main"
      type="overlay"
      swipeGesture={props.swipe}
      hidden={props.hidden ? props.hidden : false}
      
    >
      <IonContent>
        <IonNote>
          {myUser.names?.split(" ")[0] + " " + myUser.last_names?.split(" ")[0]}
        </IonNote>
        <IonList id="inbox-list" lines="none">
          {appPages.map((appPage, index) => {
            switch (appPage.title) {
              case "Cerrar Sesion":
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      onClick={logOut}
                      className="noRedirect"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon slot="start" md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              case "Estado Real":
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.url ? "selected" : ""
                      }
                      routerLink={appPage.url}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon slot="start" md={appPage.mdIcon} />
                      <IonLabel>{"Organizaciones"}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              default:
                if (
                  appPage.title === "Sensores" ||
                  appPage.title === "Archivos de firmware" ||
                  appPage.title === "Terminales" ||
                  appPage.title === "Usuarios Mosquito"
                ) {
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                } else {
                  return null;
                }
            }
          })}
        </IonList>
      </IonContent>
      <AlertModal modal={alertModal} checked={false} />
      <CodeUserModal modal={codeUserModal} />
    </IonMenu>
  ) : (
    <IonMenu
      ref={menuRef}
      className="menu"
      contentId="main"
      type="overlay"
      swipeGesture={props.swipe}
      hidden={props.hidden ? props.hidden : false}
    >
      <IonContent>
        <div className="sensorStatecontainer">
          <IonMenuButton className="sensorStateButton"/>          
          <IonTitle className="sensorStateButtonText">Menú Principal</IonTitle>
        </div>
        <IonNote className="noteGrid">
          {myUser.names?.split(" ")[0] + " " + myUser.last_names?.split(" ")[0]}
          <br/>
          
          <span>{myUser.organization?.name}</span>
        </IonNote>
        <IonList id="inbox-list" lines="none">
          {appPages.map((appPage, index) => {
            switch (appPage.title) {
              case "Cerrar Sesion":
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className="noRedirect"
                      onClick={logOut}
                      lines="none"
                      detail={false}
                    >
                      <IonIcon slot="start" md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              case "Alertas":
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      routerLink={appPage.url}
                      routerDirection="none"
                      onClick={() => alertModal.current?.present()}
                      className="noRedirect"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon slot="start" md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              case "Agregar perfiles":
                if (myUser.roles) {
                  return myUser?.roles.includes("Operator") ? null : (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className="noRedirect"
                        routerLink={appPage.url}
                        routerDirection="none"
                        onClick={() => codeUserModal.current?.present()}
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                } else {
                  return null;
                }
              case "Administrar usuarios":
                if (myUser.roles) {
                  return myUser?.roles.includes("Operator") ? null : (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                } else {
                  return null;
                }
              default:
                if (
                  appPage.title !== "Sensores" &&
                  appPage.title !== "Archivos de firmware" &&
                  appPage.title !== "Terminales" &&
                  appPage.title !== "Usuarios Mosquito" 
                ) {
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                } else {
                  return null;
                }
            }
          })}
        </IonList>
        <IonGrid className="footer">
          <IonRow className="footerRow">
            <IonText>Soporte Técnico</IonText>
          </IonRow>
          <IonRow className="footerRow">
            <IonText>
              <a
                style={{ textDecoration: "none", color: "#1e1e1e" }}
                href={encodeURI(
                  `https://api.whatsapp.com/send?phone=+56941093164&text=Hola Soy ${
                    myUser.names?.split(" ")[0]
                  } ${myUser.last_names?.split(" ")[0]}, de la organizacion ${myUser.organization?.name}, de la empresa ${
                    myUser.company
                  }`
                )}
              >
                +56 9 4109 3164
              </a>
            </IonText>
          </IonRow>
          <IonRow className="footerIcon">
            <IonIcon icon="assets/icons/logo-sunandplay-contact.svg" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <AlertModal modal={alertModal} checked={myUser.emailNotify as boolean} />
      <CodeUserModal modal={codeUserModal} />
    </IonMenu>
  );
};

export default Menu;
