// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fota_sensorselectSelect__VFBux{\n  max-width: 100% !important;\n  width: 100% !important;\n  padding-left: 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/SuperAdmin/Terms/Fota/Fota.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".sensorselectSelect{\n  max-width: 100% !important;\n  width: 100% !important;\n  padding-left: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sensorselectSelect": "Fota_sensorselectSelect__VFBux"
};
export default ___CSS_LOADER_EXPORT___;
