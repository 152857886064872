import React, { useEffect, useState } from "react";
import styles from "./SpliceModal.module.css";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonModal,
  IonText,
  isPlatform,
} from "@ionic/react";
import { useHistory } from "react-router";

const SpliceModal: React.FC<{
  idSplice: React.MutableRefObject<string>;
  idBlueprint: React.MutableRefObject<string>;
  modal: React.RefObject<HTMLIonModalElement>;
  company: React.MutableRefObject<string>;
  side: React.MutableRefObject<string>;
  phoneCompany: React.MutableRefObject<string>;
}> = (props) => {
  const modal = props.modal;
  const history = useHistory();
  const [update, setUpdate] = useState<boolean>(true);
  useEffect(() => {
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openBlueprint = (idBlueprint: string,idSplice:string) => {
    history.push(`/page/SpliceBlueprint?idBlueprint=${idBlueprint}&idSplice=${idSplice}`);
    modal.current?.dismiss();
  };

  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.8;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }

  return (
    <IonModal
      ref={modal}
      initialBreakpoint={ isPlatform("desktop") ? 0.5: calculateHeigthOfModal()}
      breakpoints={isPlatform("desktop") ? [0,0.5]:[0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        e.target.dismiss();
      }}
      onWillPresent={(e) => {
        setUpdate(!update);
      }}
      className={styles.splicemodalModal}
    >
      <IonContent className="ion-justify-content-center">
      <div className={styles.MainContainer}>
          <IonGrid className={styles.splicemodalItem}>
            <IonGrid className={styles.splicemodalTextContainer}>
              <IonText>Orientación: {props.side.current}</IonText>
              <IonText>dirección del conjunto de luminarias asociadas</IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.splicemodalItem}>
            <IonGrid className={styles.splicemodalTextContainer}>
              <IonText className={styles.splicemodalButtonContainer}>
                Plano del conjunto:{" "}
                <IonButton
                  className={styles.splicemodalBlueprint}
                  onClick={() => openBlueprint(props.idBlueprint.current,props.idSplice.current)}
                >
                  <IonText className={styles.splicemodalBlueprintBUttonText}>ABRIR</IonText>
                </IonButton>
              </IonText>
              <IonText>encontraras la ubicación del tablero</IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.splicemodalItem}>
            <IonGrid className={styles.splicemodalTextContainer}>
              <IonText>N° proveedor: {props.phoneCompany.current}</IonText>
              <IonText>
                empresa asociada al servicio: {props.company.current}
              </IonText>
            </IonGrid>
          </IonGrid>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SpliceModal;
