import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import styles from "./BlueprintList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import ListLoading from "../../Loading/ListLoading";
import {
  trashBinOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";

const BlueprintList: React.FC = () => {
  const { socket, presentToast, url, token } = useContext(AuthInfoContext);
  const [items, setItems] = useState<{ _id: string; name: string }[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    socket?.on("blueprintChanged", () => {
      getSensors();
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("blueprintChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSensors = async (event?: any) => {
    const respuesta = await axios.get(url + `blueprint/myorganization?limit=15&page=${currentPage.current}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if (loading) {
      setLoading(false);
    }
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSensors(event);
      } else {
        getSensors();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const deleteFirmware = async (id: string) => {
    await axios
      .delete(url + `blueprint/${id}`, {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          presentToast(
            res.data.message,
            checkmarkCircleOutline,
            "success",
            "top",
            1500
          );
        } else {
          presentToast(res.data.message, alertCircleOutline, "danger", "top");
        }
      })
      .catch((res) =>
        presentToast(res.message, alertCircleOutline, "danger", "top")
      );
  };
  if (loading) {
    return (
      <IonContent className={styles.editsensorContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.editsensorContent}>
        {items.map((item, index) => (
          <IonRow
            key={index}
            className={styles.editsensorRow}
            id="open-sensor-state"
          >
            <IonCol size="10">
              <IonText>{item.name}</IonText>
            </IonCol>
            <IonCol size="2">
              <IonIcon
                icon={trashBinOutline}
                className={styles.firmwareDeleteIcon}
                onClick={() => deleteFirmware(item._id)}
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    );
  }
};

export default BlueprintList;
