import {
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
  isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./UserList.module.css";
import ApproveUserModal from "../../Modals/Users/ApproveUserModal";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { User } from "../../../context/interfaces-context";
import ListLoading from "../../Loading/ListLoading";
import ConfirmDeleteUserModal from "../../Modals/Users/ConfirmDeleteUserModal";

const UserList: React.FC = () => {
  const { socket, url, token} = useContext(AuthInfoContext);
  const [items, setItems] = useState<User[]>([]);
  const modal = useRef<HTMLIonModalElement>(null);
  const confirmModal = useRef<HTMLIonModalElement>(null);
  const [verifyUser, setVerifyUser] = useState<boolean>(false);
  const [idUser, setIdUser] = useState<string>("");
  const [roleUser, setRoleUser] = useState<string>("");
  const [userNames,setUserNames] = useState<string>('')
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const getSplice = async (event?: any) => {
    const respuesta = await axios.get(
      url + `user/myorganization?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if (loading) {
      setLoading(false);
    }
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSplice(event);
      } else {
        getSplice();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };

  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    socket?.on("userChanged", () => {
      currentPage.current = 1;
      getSplice();
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("userChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showuserState = (
    verify: boolean | undefined,
    id: string | undefined,
    role: string[] | undefined
  ) => {
    setVerifyUser(verify as boolean);
    setIdUser(id as string);
    if (role) {
      setRoleUser(role[0]);
    }
    modal.current?.present();
  };

  const userName = (user: User) => {
    if (user.names && user.last_names) {
      let name = user.names.split(" ")[0];
      let lastName = user.last_names.split(" ")[0];
      return name + " " + lastName;
    } else {
      return "";
    }
  };
  const userRole = (user: User) => {
    let typeUser = "";
    if (user.roles) {
      switch (user.roles[0]) {
        case "SuperAdministrator":
          typeUser = "0";
          break;
        case "Administrator":
          typeUser = "Administrador";
          break;
        case "Operator":
          typeUser = "Operador";
          break;
        default:
          break;
      }
    }
    return typeUser;
  };

  const userDelete = (id: string | undefined,username:string) => {
    setIdUser(id as string)
    setUserNames(username)
    confirmModal.current?.present()
  };
  if (loading) {
    return (
      <IonContent className={styles.userListContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.userListContent}>
        {items.map((item, index) => (
          <IonRow
            key={item.email}
            className={styles.userListRow}
            id="open-user-state"
          >
            <IonCol size="5.25" className={styles.userListCol1}>
              <IonText>{item.names?.split(" ")[0] +' '+ item.last_names?.split(" ")[0]}</IonText>
            </IonCol>
            <IonCol size="3" className={styles.userListCol}>
              <IonText>{userRole(item)}</IonText>
            </IonCol>
            <IonCol size="1.6" className={styles.userListCol}>
              <IonIcon
                onClick={() => userDelete(item._id,userName(item))}
                icon="assets/icons/logo-delete-sensor.svg"
              />
            </IonCol>
            <IonCol size="2.1" className={styles.userListCol}>
              <IonIcon
                className={styles.userListIcon}
                icon={
                  item.verify
                    ? "assets/icons/request-user-verify.svg"
                    : "assets/icons/request-user-pending.svg"
                }
                onClick={
                  item.verify
                    ? () => showuserState(true, item._id, item.roles)
                    : () => showuserState(false, item._id, item.roles)
                }
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <ApproveUserModal
          modal={modal}
          checked={verifyUser}
          id={idUser}
          role={roleUser}
        />
        <ConfirmDeleteUserModal modal={confirmModal} id={idUser} username={userNames}/>
      </IonContent>
    );
  }
};

export default UserList;
