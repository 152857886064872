import {
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
  IonGrid,
  useIonViewDidLeave,
  isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./OrganizationList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import { Organization } from "../../../../context/interfaces-context";
import ListLoading from "../../../Loading/ListLoading";
import OrganizationData from "./OrganizationData";

const OrganizationList: React.FC = () => {
  const { url, token, socket } = useContext(AuthInfoContext);
  const [items, setItems] = useState<Organization[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const gridContainer = useRef<HTMLIonGridElement[]>([]);
  const userdata = useRef<boolean[]>([false]);

  const getOrganizations = async (event?: any) => {
    const respuesta = await axios.get(
      url + `organization?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if (loading) {
      setLoading(false);
    }
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (event) {
      getOrganizations(event);
    } else {
      getOrganizations();
    }
  };
  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    socket?.on("organizationChanged", () => {
      getOrganizations();
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("organizationChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const displayInfo = (id: string, index: number) => {
    if (gridContainer.current[index].hidden) {
      gridContainer.current[index].removeAttribute("hidden");
      if (!userdata.current[index]) {
        socket?.emit("organizationDataAmountJoin", {
          idOrganization: id,
          token: token,
        });
        userdata.current[index] = true;
      }
    } else {
      socket?.emit("organizationDataAmountLeave", {
        idOrganization: id,
        token: token,
      });
      gridContainer.current[index].setAttribute("hidden", "true");
    }
  };
  useIonViewDidLeave(() => {
    items.forEach((i, index) => {
      if (!gridContainer.current[index].hidden) {
        gridContainer.current[index].setAttribute("hidden", "true");
      }
    });
  });
  if (loading) {
    return (
      <IonContent className={styles.organizationListContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.organizationListContent}>
        {items.map((item, index) => (
          <IonGrid key={item._id + "container"} className="ion-no-padding">
            <IonRow
              key={item._id + "row"}
              className={styles.organizationListRow}
              id="open-organization-state"
            >
              <IonCol
                key={item._id + "col1"}
                size="3"
                className={styles.organizationListCol1}
              >
                <IonText>{item.vat}</IonText>
              </IonCol>
              <IonCol
                key={item._id + "col2"}
                size="3.75"
                className={styles.organizationListCol1}
              >
                <IonText>{item.name}</IonText>
              </IonCol>
              <IonCol
                key={item._id + "col3"}
                size="3.75"
                className={styles.organizationListCol1}
              >
                <IonText>{item.address}</IonText>
              </IonCol>
              <IonCol
                key={item._id + "col4"}
                size="1.5"
                className={styles.organizationListCol}
              >
                <IonIcon
                  className={styles.organizationListIcon}
                  icon={"assets/icons/logo-edit-sensor.svg"}
                  onClick={() => displayInfo(item._id as string, index)}
                />
              </IonCol>
            </IonRow>
            <IonGrid
              key={item._id + "grid"}
              ref={(el) =>
                (gridContainer.current[index] = el as HTMLIonGridElement)
              }
              hidden
              className="ion-no-padding"
            >
              <OrganizationData
                id={item._id as string}
                index={index}
                verify={item.verify as boolean}
              />
            </IonGrid>
          </IonGrid>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    );
  }
};

export default OrganizationList;
