import {
  IonPage,
  IonContent,
  IonTitle,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import styles from "./SpliceBlueprint.module.css";
import AuthInfoContext from "../../context/authinfo-context";

// Import styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import PdfViewer from "../../components/Pdf/PdfViewer";
import BlueprintModal from "../../components/Modals/Blueprints/BlueprintModal";

const SpliceBlueprint: React.FC = () => {
  const { url, token } = useContext(AuthInfoContext);
  const queryParams = new URLSearchParams(window.location.search);
  const idBlueprint = queryParams.get("idBlueprint");
  const idSplice = queryParams.get("idSplice");
  const history = useHistory();
  const backTo = () => {
    history.goBack();
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.spliceblueprintButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.spliceblueprintIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className={styles.homeHeaderButtons}>
            <IonButton
              id="open-blueprint-modal"
              className={styles.spliceblueprintButtonIconAdd}
              shape="round"
            >
              <IonIcon className={styles.spliceblueprintIconAdd} icon='assets/icons/add-file.svg' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      
      <IonContent
        fullscreen
        className={
          styles.spliceblueprintContenedor +
          " ion-display-flex ion-text-center ion-justify-content-center ion-align-items-center "
        }
      >
        <IonTitle className={styles.homeTitle}>PLANIMETRÍA</IonTitle>
        <PdfViewer
          fileUrl={url + `blueprint/${idBlueprint}`}
          httpHeaders={{ Authorization: `Bearer ${token}` }}
        />
      </IonContent>
      <BlueprintModal trigger="open-blueprint-modal" idSplice={idSplice} />
    </IonPage>
  );
};

export default SpliceBlueprint;
