import { IonButton, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import React from "react";
import styles from "./ChangePasswordModal.module.css";
const ChangePasswordModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  email: React.RefObject<string | undefined>;
  userEmail?: string
}> = (props) => {
  return (
    <IonModal ref={props.modal} className={styles.changePasswordModalModal}>
      <IonGrid className={styles.changePasswordModalContainer}>
        <IonRow className={styles.changePasswordModalRow}>
          <IonText className={styles.changePasswordModalText}>
            Enviamos un correo electrónico a {props.userEmail ? props.userEmail : props.email.current} con un enlace
            para restablecer la contraseña.
          </IonText>
        </IonRow>
        <IonRow className={styles.changePasswordModalRowButton}>
          <IonButton
            className={styles.changePasswordModalButton}
            expand="block"
            fill="outline"
            onClick={() => props.modal.current?.dismiss()}
          >
            ACEPTAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ChangePasswordModal;
