import React, { useContext, useRef, useState } from "react";
import styles from "./EditSensorForm.module.css";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router";
import axios from "axios";
import {
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import AuthInfoContext from "../../../context/authinfo-context";
import { Splice, decodeB64 } from "../../../context/interfaces-context";
import { Geolocation } from "@capacitor/geolocation";

const EditSensorForm: React.FC = () => {
  const { token, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory();
  const submitButton = useRef<HTMLIonButtonElement>();
  const queryParams = new URLSearchParams(window.location.search);
  const sensor = queryParams.get("sensor");
  const sensorData = useRef<Splice>(
    sensor ? decodeB64(sensor as string) : ({} as Splice)
  );
  const ruta = useRef<string | undefined>(sensorData.current.stretch);
  const sentidoRuta = useRef<string | undefined>(sensorData.current.side);
  const estructura = useRef<string | undefined>(
    sensorData.current.infrastructure
  );
  const nombreDispositivo = useRef<string | undefined>(sensorData.current.name);
  const kilometro = useRef<string | undefined>(sensorData.current.pk);
  const coordenadasGPS = useRef<string | undefined>(
    sensorData.current.longitude + " " + sensorData.current.latitude
  );
  const numeroLuminarias = useRef<string | undefined>(
    sensorData.current.number_of_luminaries
  );
  const [luminariasMixtas, setLuminariasMixtas] = useState<boolean | undefined>(
    sensorData.current.mixed_luminaire
  );
  const corrienteMinima = useRef<string | undefined>(
    sensorData.current.minimum_current
  );
  const compañia = useRef<string | undefined>(sensorData.current.company);
  const telefonoCompañia = useRef<string | undefined>(
    sensorData.current.phone_company
  );
  const corrienteNominal = useRef<string | undefined>(
    sensorData.current.rated_current
  );
  const [currentPosition, setCurrentPosition] = useState<string>(
    sensorData.current.latitude + " , " + sensorData.current.longitude
  );

  const backTo = () => {
    history.replace("/page/EditSensor");
  };

  const checkValues = () => {
    if (
      ruta.current &&
      sentidoRuta.current &&
      estructura.current &&
      nombreDispositivo.current &&
      kilometro.current &&
      coordenadasGPS.current &&
      numeroLuminarias.current &&
      corrienteNominal.current &&
      compañia.current &&
      telefonoCompañia.current
    ) {
      submitButton.current?.removeAttribute("disabled");
    }
  };

  const updateSensor = async (data: Splice) => {
    console.log(data);
    const respuesta = await axios.put(
      url + "splice/" + sensorData.current._id,
      data,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.status === 200) {
      presentToast(
        respuesta.data.message,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      history.replace("/page/EditSensor");
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };

  const submit = () => {
    let dataCurrent: Splice = {};
    let coords = coordenadasGPS.current?.split(",");
    if (luminariasMixtas) {
      dataCurrent = {
        stretch: ruta.current,
        side: sentidoRuta.current,
        infrastructure: estructura.current,
        name: nombreDispositivo.current,
        pk: kilometro.current,
        latitude: coords ? coords[0] : "",
        longitude: coords ? coords[1] : "",
        number_of_luminaries: numeroLuminarias.current,
        rated_current: corrienteNominal.current,
        mixed_luminaire: luminariasMixtas,
        minimum_current: corrienteMinima.current,
        company: compañia.current,
        phone_company: telefonoCompañia.current,
      };
    } else {
      dataCurrent = {
        stretch: ruta.current,
        side: sentidoRuta.current,
        infrastructure: estructura.current,
        name: nombreDispositivo.current,
        pk: kilometro.current,
        latitude: coords ? coords[0] : "",
        longitude: coords ? coords[1] : "",
        number_of_luminaries: numeroLuminarias.current,
        rated_current: corrienteNominal.current,
        mixed_luminaire: luminariasMixtas,
        minimum_current: parseFloat(
          (
            parseInt(corrienteNominal.current as string) /
            parseInt(numeroLuminarias.current as string)
          ).toString()
        ).toFixed(2),
        company: compañia.current,
        phone_company: telefonoCompañia.current,
      };
    }
    updateSensor(dataCurrent);
  };

  const setCurrentLocation = async () => {
    setCurrentPosition("Obteniendo coordenadas...");
    const coordinates = await Geolocation.getCurrentPosition();
    //console.log(coordinates);
    setCurrentPosition(
      coordinates.coords.latitude + " , " + coordinates.coords.longitude
    );
    //console.log(coordinates.coords.latitude, coordinates.coords.longitude);
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.editsensorformButtons}>
            <IonButton
              shape="round"
              className={styles.editsensorformButtonIcon}
              onClick={backTo}
            >
              <IonIcon
                className={styles.editsensorformIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className={styles.editsensorformContainer}>
          <IonTitle className={styles.editsensorformTitle} size="large">
            EDITAR CONFIGURACIÓN
          </IonTitle>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Ruta asociada y sentido
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Ruta asociada - Sentido ruta"
                  value={ruta.current + " - " + sentidoRuta.current}
                  onIonChange={(e) => {
                    checkValues();
                    let splited = e.target.value?.toString().split(" - ");
                    ruta.current = splited ? splited[0] : "";
                    sentidoRuta.current = splited ? splited[1] : "";
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Tipo de estructura
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Tipo de estructura"
                  value={estructura.current}
                  onIonChange={(e) => {
                    checkValues();
                    estructura.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Nombre del dispositivo
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Nombre del dispositivo"
                  value={nombreDispositivo.current}
                  onIonChange={(e) => {
                    checkValues();
                    nombreDispositivo.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Kilometro asociado
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Kilometro asociado"
                  value={kilometro.current}
                  onIonChange={(e) => {
                    checkValues();
                    kilometro.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRowGPS}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInputGPS} lines="none">
                <IonLabel position="floating" color="dark">
                  Coordenadas GPS
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Coordenadas GPS"
                  value={currentPosition}
                  onIonChange={(e) => {
                    checkValues();
                    coordenadasGPS.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
              <IonIcon
                className={styles.editsensorformIconGPS}
                icon='assets/icons/gps-icon.svg'
                onClick={setCurrentLocation}
              />
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonItem
              className={styles.editsensorformInputLuminaries}
              lines="none"
            >
              <IonCol className="ion-text-center" size="5.75">
                <IonItem lines="none">
                  <IonLabel position="floating" color="dark">
                    Nº de luminarias
                  </IonLabel>
                  <IonInput
                    color="dark"
                    placeholder="Nº de luminarias"
                    value={numeroLuminarias.current}
                    onIonChange={(e) => {
                      checkValues();
                      numeroLuminarias.current = e.target.value?.toString();
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="ion-text-center">
                <IonItem lines="none">
                  <IonLabel position="stacked">Tipo de Empalme</IonLabel>
                  <IonSelect
                    placeholder="Ninguno"
                    interface="popover"
                    value={luminariasMixtas}
                    onIonChange={(e) => setLuminariasMixtas(e.target.value)}
                  >
                    <IonSelectOption value={false}>Uniforme</IonSelectOption>
                    <IonSelectOption value={true}>Mixta</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonItem>
          </IonRow>

          {luminariasMixtas ? (
            <IonRow className={styles.editsensorformRow}>
              <IonCol className="ion-text-center">
                <IonItem className={styles.editsensorformInput} lines="none">
                  <IonLabel position="floating" color="dark">
                    Corriente minima de consumo
                  </IonLabel>
                  <IonInput
                    placeholder="Corriente minima de consumo"
                    value={corrienteNominal.current}
                    onIonChange={(e) => {
                      checkValues();
                      corrienteMinima.current = e.target.value?.toString();
                    }}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          ) : (
            <></>
          )}

          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Corriente nominal medida
                </IonLabel>
                <IonInput
                  placeholder="Corriente nominal medida"
                  value={corrienteNominal.current}
                  onIonChange={(e) => {
                    checkValues();
                    corrienteNominal.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Empresa eléctrica
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder="Empresa eléctrica"
                  value={compañia.current}
                  onIonChange={(e) => {
                    checkValues();
                    compañia.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.editsensorformRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.editsensorformInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Nº de atencion al cliente
                </IonLabel>
                <IonInput
                  color="dark"
                  placeholder=" Nº de atencion al cliente"
                  inputMode="numeric"
                  type="tel"
                  value={telefonoCompañia.current}
                  maxlength={13}
                  onIonChange={(e) => {
                    checkValues();
                    telefonoCompañia.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.editsensorformButton}
                expand="block"
                fill="outline"
                onClick={submit}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditSensorForm;
