import { IonButton, IonGrid, IonInput, IonText } from "@ionic/react";
import React from "react";
import styles from "./CodeNumber.module.css";
import { useRef } from "react";
import { Clipboard } from "@capacitor/clipboard";
import { isPlatform } from "@ionic/react";

const CodeNumber: React.FC<{
  length: number;
  sendCode: (code: string) => void;
  cancel?: boolean;
  handlerCancel?: () => void;
}> = (props) => {
  const position = useRef<Array<HTMLIonInputElement>>([]);
  const avaibleClipboard = useRef<boolean>(true);

  const numberController = (event: any, next: any, prev: any, index: any) => {
    if (!isPlatform("desktop")) {
      checkClipboard();
    }
    if (event.target.value.length < 1 && prev !== null) {
      position.current[prev]?.setFocus();
    } else if (
      next &&
      event.target.value.length > 0 &&
      event.key !== "Backspace"
    ) {
      position.current[next]?.setFocus();
    } else {
      return 0;
    }
  };
  const keyPressControlller = (event: any, index: number) => {
    if (event.target.value.length < 1) {
      if (index > 0) {
        if (event.key === "Backspace") {
          position.current[index - 1]?.setFocus();
        }
      } else {
        return 0;
      }
    } else if (event.key === "Enter" && index === 7) {
      sendCode();
    } else {
      numberController(event, index + 1, index - 1, index);
    }
  };
  const sendCode = () => {
    let numberArray = position.current.map((p) => p?.value);
    let number = numberArray.join("");
    props.sendCode(number);
  };
  const checkClipboard = async () => {
    if (avaibleClipboard.current) {
      const { value } = await Clipboard.read();
      let codePaste = value.split("");
      position.current.map((p, i) => (p.value = codePaste[i]));
      avaibleClipboard.current = false;
    }
  };
  const onPasteController = (event: any) => {
    event.preventDefault();
    let codePaste = event.clipboardData.getData("text").split("");
    position.current.map((p, i) => (p.value = codePaste[i]));
  };
  const generateInputs = () => {
    const inputs = [];
    for (let i = 0; i < props.length; i++) {
      if (i === 0) {
        inputs.push(
          <IonInput
            key={i}
            className={styles.codeInputNumber}
            maxlength={1}
            inputmode="numeric"
            // eslint-disable-next-line no-loop-func
            ref={(el: HTMLIonInputElement) => (position.current[i] = el)}
            autofocus
            onKeyDown={(e) => keyPressControlller(e, i)}
            onPaste={(e) => onPasteController(e)}
            onIonChange={(e) => {
              numberController(e, i + 1, null, i);
            }}
          />
        );
      } else {
        inputs.push(
          <IonInput
            key={i}
            className={styles.codeInputNumber}
            maxlength={1}
            inputmode="numeric"
            // eslint-disable-next-line no-loop-func
            ref={(el: HTMLIonInputElement) => (position.current[i] = el)}
            onKeyDown={(e) => keyPressControlller(e, i)}
            onPaste={(e) => onPasteController(e)}
            onIonChange={(e) => {
              numberController(e, i + 1, i - 1, i);
            }}
          />
        );
      }
    }
    return inputs;
  };
  return (
    <>
      <IonGrid className={styles.codeContainerNumber}>
        {generateInputs()}
      </IonGrid>

      {props.cancel ? (
        <>
          <IonButton className={styles.codeButtonDone} onClick={sendCode}>
            ACEPTAR
          </IonButton>
          <IonButton
            className={styles.codeButtonCancel}
            onClick={props.handlerCancel}
          >
            CANCELAR
          </IonButton>
        </>
      ) : (
        <IonButton className={styles.codeButton} onClick={sendCode}>
        
        <IonText className={styles.codeButtonText}>ACEPTAR</IonText>
        
      </IonButton>
      )}
    </>
  );
};

export default CodeNumber;
