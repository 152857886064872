import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useRef } from "react";
import styles from "./ConfirmDeleteOrganization.module.css";
const ConfirmDeleteOrganization: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  deleteOrganization: ()=>void
}> = ({ modal , deleteOrganization}) => {
  const input = useRef<string | undefined>('')
  const doneButton = useRef<HTMLIonButtonElement>(null)
  const checkValue =(e:any)=>{
    if(e.target.value.toString() === 'ELIMINAR'){
      doneButton.current?.removeAttribute('disabled')
    }else{
      doneButton.current?.setAttribute('disabled','true')
    }
  }
  return (
    <IonModal ref={modal} className={styles.confirmDeleteOrganizationModal}>
      <IonGrid className={styles.confirmDeleteOrganizationContainer}>
        <IonRow className={styles.confirmDeleteOrganizationRow + ' ion-justify-content-center ion-no-padding'}>
          <IonText className={styles.confirmDeleteOrganizationTittle + " ion-no-padding"}>
            Ingresa la palabra ELIMINAR para confirmar que deseas eliminar la organizacion
          </IonText>
        </IonRow>
        <IonRow className={styles.confirmDeleteOrganizationRow}>
          <IonItem className={styles.confirmDeleteOrganizationInput}>
            <IonLabel position="floating" color="dark">
            </IonLabel>
            <IonInput
              placeholder="ELIMINAR"
              required
              type="text"
              onIonChange={(e)=> {checkValue(e);input.current = e.target.value?.toString()}}
            />  
          </IonItem>
        </IonRow>
        <IonRow className={styles.confirmDeleteOrganizationRowButton}>
          <IonCol size="5.5">
            <IonButton
              className={styles.confirmDeleteOrganizationButton}
              expand="block"
              fill="outline"
              disabled
              ref={doneButton}
              onClick={deleteOrganization}
            >
              ACEPTAR
            </IonButton>
          </IonCol>
          <IonCol size="5.5">
            <IonButton
              className={styles.confirmDeleteOrganizationButton}
              expand="block"
              fill="outline"
              onClick={() => modal.current?.dismiss()}
            >
              CANCELAR
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ConfirmDeleteOrganization;
