import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { useContext, useEffect } from "react";
import AuthInfoContext from "./context/authinfo-context";
import { createStore, get as getStorage } from "./data/Storage";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { Worker } from "@react-pdf-viewer/core";
import { Keyboard } from "@capacitor/keyboard";
import packageJson from "../package.json";
//const MyComponent = lazy(() => import("./MyComponent"));
import Map from "./components/Map/Map";
import Menu from "./components/Menu/Menu";
import AppUrlListener from "./components/AppUrlListener/AppUrlListener";
import Main from "./pages/Main/Main";
import Login from "./pages/Login/Login";
import AddSensorConfig from "./pages/Sensors/AddSensor/AddSensorConfig";
import CodeSignIn from "./pages/SignIn/CodeSignIn/CodeSignIn";
import SignIn from "./pages/SignIn/SignInForm/SignIn";
import MyProfile from "./pages/User/MyProfile/MyProfile";
import AddSensorCode from "./pages/Sensors/AddSensor/AddSensorCode";
import Blueprints from "./pages/Blueprints/Blueprints";
import AdminUser from "./pages/User/AdminUsers/AdminUsers";
import EditSensor from "./pages/Sensors/EditSensor/EditSensor";
import EditSensorForm from "./pages/Sensors/EditSensor/EditSensorForm";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import SpliceBlueprint from "./pages/Splice/SpliceBlueprint";
import FirmwareFiles from "./pages/SuperAdmin/FirmwareFiles/FirmwareFiles";
import Terms from "./pages/SuperAdmin/Terms/Terms";
import CreateOrganization from "./pages/SuperAdmin/Organizations/CreateOrganization";
import AdminOrganizationUsers from "./pages/SuperAdmin/Organizations/Users/AdminOrganizationUsers";
import AdminOrganizationSensors from "./pages/SuperAdmin/Organizations/Sensors/AdminOrganizationSensors";
import AllSensors from "./pages/SuperAdmin/AllSensors/AllSensors";
import MosquitoUsers from "./pages/SuperAdmin/MosquitoUsers/MosquitoUsers";
import CreateMosquitoUser from "./pages/SuperAdmin/MosquitoUsers/CreateMosquitoUser/CreateMosquitoUser";
import EditMosquitoUser from "./pages/SuperAdmin/MosquitoUsers/EditMosquitoUser/EditMosquitoUser";

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
setupIonicReact();

const App: React.FC = () => {
  if (!isPlatform("desktop")) {
    Keyboard.addListener("keyboardWillShow", (info: any) => {
      document.getElementsByTagName("ion-backdrop")[0].style.height = "130%";
    });

    Keyboard.addListener("keyboardWillHide", () => {
      document.getElementsByTagName("ion-backdrop")[0].style.height = "auto";
    });
  }
  const {
    setToken,
    logIn,
    getMyUser,
    logged,
    setLogged,
    setLoadData,
    setUserRol,
  } = useContext(AuthInfoContext);
  useEffect(() => {
    if (!isPlatform("desktop")) {
      const setupStore = async () => {
        await createStore("DataStorage");
        const tokenStorage: string = await getStorage("token");
        const loggedStorage: boolean = await getStorage("logged");
        const userRolStorage: string = await getStorage("userRol");
        if (tokenStorage && loggedStorage && userRolStorage) {
          setUserRol(userRolStorage);
          getMyUser(tokenStorage);
          setToken(tokenStorage);
          setLogged(loggedStorage);
          logIn(tokenStorage);
        }
        setLoadData(true);
      };
      setupStore();
    }

    return () => {
      if (isPlatform("desktop")) {
        //console.log('owo')
        const setupStore = async () => {
          await createStore("DataStorage");
          const tokenStorage: string = await getStorage("token");
          const loggedStorage: boolean = await getStorage("logged");
          const userRolStorage: string = await getStorage("userRol");
          if (tokenStorage && loggedStorage && userRolStorage) {
            setUserRol(userRolStorage);
            setToken(tokenStorage);
            setLogged(loggedStorage);
            logIn(tokenStorage);
            getMyUser(tokenStorage);
          }
          setLoadData(true);
        };
        setupStore();
      }
      //console.log("owo");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <IonApp>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
      >
        <IonReactRouter>
          <AppUrlListener />
          <IonSplitPane contentId="main">
            {logged ? (
              <Menu swipe={true} />
            ) : (
              <Menu swipe={false} hidden={true} />
            )}
            <IonRouterOutlet id="main" animated>
              <Route path="/Main" exact component={Main} />
              <Route render={() => <Redirect to="/Main" />} />
              <ProtectedRoute path="/page/MyProfile" component={MyProfile} />
              <ProtectedRoute
                path="/page/AddSensorCode"
                component={AddSensorCode}
              />
              <ProtectedRoute
                path="/page/AddSensorConfig"
                component={AddSensorConfig}
              />
              <ProtectedRoute path="/page/EditSensor" component={EditSensor} />
              <ProtectedRoute
                path={`/page/EditSensorForm`}
                component={EditSensorForm}
              />
              <ProtectedRoute path="/page/AdminUsers" component={AdminUser} />
              <ProtectedRoute path="/page/Blueprints" component={Blueprints} />
              <ProtectedRoute
                path="/page/SpliceBlueprint"
                component={SpliceBlueprint}
              />
              <ProtectedRoute
                path="/page/FirmwareFiles"
                component={FirmwareFiles}
              />
              <ProtectedRoute
                path="/page/CreateOrganization"
                component={CreateOrganization}
              />
              <ProtectedRoute
                path="/page/OrganizationUsers"
                component={AdminOrganizationUsers}
              />
              <ProtectedRoute
                path="/page/OrganizationSensors"
                component={AdminOrganizationSensors}
              />
              <ProtectedRoute path="/page/AllSensors" component={AllSensors} />
              <ProtectedRoute path="/page/Terms" component={Terms} />
              <ProtectedRoute
                path="/page/MosquitoUsers"
                component={MosquitoUsers}
              />
              <ProtectedRoute
                path="/page/CreateMosquitoUser"
                component={CreateMosquitoUser}
              />
              <ProtectedRoute
                path="/page/EditMosquitoUser"
                component={EditMosquitoUser}
              />
              <ProtectedRoute path="/page/Map" component={Map} />
              <Route path="/SignIn" exact component={SignIn} />
              <Route path="/CodeSignIn" exact component={CodeSignIn} />
              <Route path="/Login" exact component={Login} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </Worker>
    </IonApp>
  );
};

export default App;
