import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonText,
  IonToggle,
  isPlatform,
  IonItem,
} from "@ionic/react";
import React, { useContext, useRef } from "react";
import styles from "./AlertModal.module.css";
import AuthInfoContext from "../../../context/authinfo-context";

const AlertModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  checked: boolean;
}> = (props) => {
  const { token, socket } = useContext(AuthInfoContext);
  const modal = props.modal;
  const modalToggle = useRef<HTMLIonToggleElement>(null);

  const enableAlert = () => {
    socket?.emit("userEmailNotify", {
      token: token,
      emailNotify: modalToggle.current?.checked as boolean,
    });
    props.modal.current?.dismiss();
  };

  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.68;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }
  return (
    <IonModal
      ref={modal}
      initialBreakpoint={isPlatform("desktop") ? 0.45 : calculateHeigthOfModal()}
      breakpoints={isPlatform("desktop") ? [0,0.45] : [0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        e.target.dismiss();
      }}
      className={styles.alertmodalModal}
    >
      <IonContent className="ion-justify-content-center">
        <div className={styles.mainContainer}>
          <IonGrid className={styles.alertmodalItemIcon}>
            <IonIcon
              icon="assets/icons/logo-alerts.svg"
              className={styles.alertmodalIcon}
            />
          </IonGrid>
          <IonGrid className={styles.alertmodalItem}>
            <IonText className={styles.alertmodalText}>Alertas:</IonText>
            <IonText className={styles.alertmodalText2}>Mail</IonText>
            <IonToggle
              ref={modalToggle}
              checked={props.checked}
              className={styles.alertmodalToggle}
            ></IonToggle>
          </IonGrid>
          <IonGrid className={styles.alertmodalItemButton}>
            <IonButton
              className={styles.alertmodalButton}
              onClick={enableAlert}
              expand="block"
              fill="outline"
            >
              <IonItem className={styles.alertmodalButtonText}>
                ACEPTAR
              </IonItem>
            </IonButton>
          </IonGrid>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AlertModal;
