import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
  getPlatforms,
} from "@ionic/react";
import styles from "./AllSensorsList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import ListLoading from "../../../Loading/ListLoading";
import { Sensor } from "../../../../context/interfaces-context";
import { alertCircleOutline } from "ionicons/icons";
import ConfirmDeleteSensor from "../../../Modals/AllSensors/ConfirmDeleteSensor";


const AllSensorsList: React.FC<{
  version: string;
  title: string;
}> = ({ version, title }) => {
  const { socket, url, token, presentToast } = useContext(AuthInfoContext);
  const [items, setItems] = useState<Sensor[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const otpCode = useRef("");
  const modal = useRef<HTMLIonModalElement>(null);
  const idSensor = useRef<number>(0);


  useEffect(() => {
    console.log("Entrando al hook useEffect")
    
    const firstBuildAndUpdate = async () => {
      console.log(getPlatforms())
      if (!isPlatform("desktop")) {
        console.log("Not Desktop");
        await firstGetSensors();
      }
      socket?.on("sensorChanged", () => {
        console.log("SensorChanged");
        currentPage.current = 1;
        getSensors();
      });
      socket?.on("sensorOtpGenerated", (msg) => {
        console.log("SensorOtpGenerated");
        otpCode.current = msg;
      });
    }
    firstBuildAndUpdate();
    return () => {
      if (isPlatform("desktop")) {
        console.log("return Desktop");
        //generateItems();
        /* intento de arreglar carga inicial */
        firstGetSensors();
      }
      socket?.off("sensorChanged");
      socket?.off("sensorOtpGenerated");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const firstGetSensors = async (event?: any) => {
    console.log("First get Sensors...")
    console.log(url + `sensor?limit=50&page=${currentPage.current}`);
    const respuesta = await axios.get(
      url + `sensor?limit=50&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          console.log("Status:")
          console.log(status);
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 2;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    setLoading(false);
  
    if (event) {
      event.target.complete();
    }
  };


  const getSensors = async (event?: any) => {
    console.log("Get Sensors...")
    console.log(url + `sensor?limit=15&page=${currentPage.current}`);
    const respuesta = await axios.get(
      url + `sensor?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          console.log("Status:")
          console.log(status);
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    setLoading(false);
  
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = async (event?: any) => {
    
    if (hasNextPage.current) {
      if (event) {
        await getSensors(event);
      } else {
        await getSensors();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
    
  };

  const getSensorCode = (code: string | null, id: number) => {
    if (code === "S/C") {
      socket?.emit("sensorOtpGenerate", { idSensor: id, token: token });
    }
  };
  const confirmDeleteSensor = (id: number) => {
    idSensor.current = id;
    modal.current?.present();
  };
  const deleteSensor = async () => {
    const respuesta = await axios.delete(url + `sensor/${idSensor.current}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (respuesta.status === 409) {
      presentToast(
        respuesta.data.message,
        alertCircleOutline,
        "danger",
        "top",
        2500
      );
    }
    if(respuesta.status === 200){
      presentToast(
        respuesta.data.message,
        alertCircleOutline,
        'success',
        "top",
        1500
      );
      modal.current?.dismiss();
    }
  };
  if (loading) {
    return (
      <IonContent className={styles.allsensorsContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.allsensorsContent}>
        {items.map((item, index) => (
          <IonRow
            key={index}
            className={styles.allsensorsRow}
            id="open-sensor-state"
          >
            <IonCol
              size="4.8"
              className={styles.allsensorsCol1}
              onClick={(e) =>
                getSensorCode(
                  e.currentTarget.childNodes.item(0).textContent,
                  item._id as number
                )
              }
            >
              <IonText>
                {title === "Codigo"
                  ? item.code !== ""
                    ? item.code
                    : "S/C"
                  : item.serial_number}
              </IonText>
            </IonCol>
            <IonCol size="1">
              <IonText>{item._id}</IonText>
            </IonCol>
            <IonCol size="3">
              <IonText>{item.state}</IonText>
            </IonCol>
            <IonCol size="2">
              <IonText>
                {version === "Version Firmware"
                  ? item.firmware_version
                  : item.hardware_version}
              </IonText>
            </IonCol>
            <IonCol size="1.2">
              <IonIcon
                className={styles.allsensorsDeleteIcon}
                icon="assets/icons/delete-sensor.svg"
                onClick={() => confirmDeleteSensor(item._id as number)}
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={ async (ev) => {
            await generateItems(ev);
            console.log("ev aqui")
            console.log(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <ConfirmDeleteSensor modal={modal} deleteSensor={deleteSensor} />
      </IonContent>
    );
  }
};

export default AllSensorsList;
