import React, {  useContext } from "react";

import { Route, Redirect } from "react-router";
import AuthInfoContext from "../../context/authinfo-context";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, path , ...rest}) => {
  const {logged} = useContext(AuthInfoContext)
  return (
    <Route path={path} {...rest} render={() => logged ? <Component {...rest} /> : <Redirect to="/Main" />} />
  );
};
