import { IonButton, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import React, { useContext } from "react";
import styles from "./ConfirmDeleteUserModal.module.css";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
const ConfirmDeleteUserModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  id: string;
  username: string;
}> = ({ modal, id, username }) => {
  const { url, token, presentToast } = useContext(AuthInfoContext);
  const deleteUser = async () => {
    await axios
      .delete(url + `user/${id}`, {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          presentToast(
            res.data.message,
            checkmarkCircleOutline,
            "success",
            "top"
          );
          modal.current?.dismiss();
        } else {
          presentToast(res.statusText, alertCircleOutline, "danger", "top");
        }
      });
  };
  return (
    <IonModal ref={modal} className={styles.modalModal}>
      <IonGrid className={styles.modalContainer}>
        <IonRow className={styles.modalRow}>
          <IonText className={styles.modalText}>
            ¿Estas seguro de querer borrar definitivamente al usuario {username}{" "}
            de tu organizacion?
          </IonText>
        </IonRow>
        <IonRow className={styles.modalRowButton}>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={deleteUser}
          >
            ACEPTAR
          </IonButton>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={() => {
              modal.current?.dismiss();
            }}
          >
            CANCELAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ConfirmDeleteUserModal;
