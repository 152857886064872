/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import {
  checkmarkCircleOutline,
  alertCircleOutline,
  addCircleOutline,
} from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import AuthInfoContext from "../../../../context/authinfo-context";
import styles from "./EditMosquitoUser.module.css";
import { UserMqtt, decodeB64 } from "../../../../context/interfaces-context";

const EditMosquitoUser: React.FC = () => {
  const { token, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search);
  const user = queryParams.get("user");
  const userMqtt = useRef<UserMqtt>(user ? decodeB64(user as string) as UserMqtt : {} as UserMqtt );
  const submitButton = useRef<HTMLIonButtonElement>();
  const username = useRef<string | undefined>(userMqtt.current.username);
  const password = useRef<string | undefined>(userMqtt.current.passwordPlain);
  const superUser = useRef<boolean | undefined>(userMqtt.current.superuser);
  const aclsRef = useRef<HTMLIonColElement[]>([]);
  const [acls, setAcls] = useState<
    { topic: string | undefined; acc: number | undefined }[]
  >(userMqtt.current.acls);
  useEffect(() => {
    return () => {
      console.log(userMqtt);
    };
  }, []);
  const checkValues = () => {
    if (username.current && password.current && superUser.current) {
      submitButton.current?.removeAttribute("disabled");
    }
  };
  const submit = () => {
    console.log(username.current,password.current,superUser.current,generateArrayAcls())
    if (username.current && password.current && superUser.current) {
      updateUserMqtt({
        username: username.current as string,
        passwordPlain: password.current as string,
        superuser: superUser.current as boolean,
        acls: generateArrayAcls(),
      });
    } else {
      presentToast(
        "Ingrese las datos para poder editar el usuario",
        alertCircleOutline,
        "danger",
        "top"
      );
    }
  };
  const updateUserMqtt = async (data:UserMqtt)=>{
    const respuesta = await axios.put(
      url + `sensor/usersmqtt/${userMqtt.current.username}`,data,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(respuesta.status === 200){
      presentToast(respuesta.statusText,checkmarkCircleOutline,'success','top')
      history.goBack()
    }

  }
  const addAcls = () => {
    setAcls([...acls, { topic: undefined, acc: undefined }]);
  };
  const generateArrayAcls = () => {
    const aclsArray = aclsRef.current.map((acls, index) => ({
      acc: (acls.childNodes[1].childNodes[0] as HTMLIonSelectElement)
        .value as number,
      topic: (
        acls.childNodes[0].childNodes[1].childNodes[0] as HTMLIonInputElement
      ).value as string,
    }));
    return aclsArray;
  };
  const deleteUserMosquito = async ()=>{
    const respuesta = await axios.delete(
      url + `sensor/usersmqtt/${userMqtt.current.username}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(respuesta)
    if(respuesta.status === 200){
      presentToast(respuesta.data.message,checkmarkCircleOutline,'success','top')
      history.goBack()
    }else {
      if(respuesta.data){
        presentToast(respuesta.data.message,alertCircleOutline,'danger','top',2000)
      }else{
        presentToast(respuesta.statusText,alertCircleOutline,'danger','top')
      }
    }
  }
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.signinButtons}>
            <IonButton
              shape="round"
              routerLink="/page/MosquitoUsers"
              routerDirection="back"
            >
              <IonIcon
                className={styles.signinIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" className={styles.signinButtons}>
            <IonButton
              shape="round"
            onClick={deleteUserMosquito}
            >
              <IonIcon
                className={styles.signinIcon}
                icon="assets/icons/logo-delete.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className={styles.container}>
          <IonTitle className={styles.signinTitle} size="large">
            CREAR USUARIO
          </IonTitle>
          <IonIcon
            className={styles.organizationIcon}
            icon="assets/icons/mosquitto.svg"
          />
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Nombre de usuario</IonLabel>
                <IonInput
                  placeholder="Nombre Usuario"
                  value={username.current}
                  onIonChange={(e) => {
                    checkValues();
                    username.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Contraseña</IonLabel>
                <IonInput
                  type="password"
                  placeholder="Contraseña"
                  value={password.current}
                  onIonChange={(e) => {
                    checkValues();
                    password.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonText className={styles.alertmodalText}>
                  SuperUsuario
                </IonText>
                <IonToggle
                  checked={superUser.current}
                  className={styles.alertmodalToggle}
                  onIonChange={(e) => (superUser.current = e.target.checked)}
                ></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          {/*<IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center" ref={aclsRef}>
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Topico</IonLabel>
                <IonInput
                  placeholder="Nombre"
                  onIonChange={(e) => {
                    checkValues();
                    name.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
              <IonItem className={styles.signinInput} >
                <IonSelect interface="popover" placeholder="Seleccionar Acc">
                  <IonSelectOption value={1} key={0}>
                    1 - Solo Lectura
                  </IonSelectOption>
                  <IonSelectOption value={2} key={1}>
                    2 - Solo Escritura
                  </IonSelectOption>
                  <IonSelectOption value={3} key={2}>
                    3 - Lectura y Escritura
                  </IonSelectOption>
                  <IonSelectOption value={4} key={3}>
                    4 - Solo Suscripcion
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>*/}
          <IonGrid>
            {acls.map((item, index) => (
              <IonRow key={"acls" + index} className={styles.signinRow}>
                <IonCol
                  className="ion-text-center"
                  ref={(el) =>
                    (aclsRef.current[index] = el as HTMLIonColElement)
                  }
                >
                  <IonItem className={styles.signinInput}>
                    <IonLabel position="floating">Topico</IonLabel>
                    <IonInput
                      placeholder="Nombre"
                      value={item.topic ? item.topic : null}
                    />
                  </IonItem>
                  <IonItem className={styles.signinInput}>
                    <IonSelect
                      interface="popover"
                      placeholder="Seleccionar Acc"
                      value={item.acc ? item.acc : null}
                    >
                      <IonSelectOption value={1} key={0}>
                        1 - Solo Lectura
                      </IonSelectOption>
                      <IonSelectOption value={2} key={1}>
                        2 - Solo Escritura
                      </IonSelectOption>
                      <IonSelectOption value={3} key={2}>
                        3 - Lectura y Escritura
                      </IonSelectOption>
                      <IonSelectOption value={4} key={3}>
                        4 - Solo Suscripcion
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
            ))}
          </IonGrid>
          <IonRow>
            <IonIcon
              className={styles.createMoquitoUserAddIcon}
              icon={addCircleOutline}
              onClick={addAcls}
            />
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.signinButton}
                expand="block"
                fill="outline"
                onClick={submit}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditMosquitoUser;
