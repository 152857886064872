import {
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import styles from "./AllSensors.module.css";
import AllSensorsList from "../../../components/Lists/SuperAdmin/SensorsList/AllSensorsList";

const AllSensors: React.FC = () => {
  const [title, setTitle] = useState<string>("Numero Serial");
  const [version, setVersion] = useState<string>("Version Firmware");
  return (
    <IonPage>
      <IonHeader className="ion-no-border ">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Menú principal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.adminuserTitleContainer}>
        <IonTitle className={styles.adminuserTitle}>SENSORES</IonTitle>
      </IonGrid>
      <IonGrid className={styles.userListContainer}>
        <IonRow className={styles.userListHeader}>
          <IonCol
            size="4.8"
            onClick={() =>
              title === "Codigo"
                ? setTitle("Numero Serial")
                : setTitle("Codigo")
            }
          >
            <IonText className="ion-vertical-align-middle">{title}</IonText>
          </IonCol>
          <IonCol size="1">
            <IonText>ID</IonText>
          </IonCol>
          <IonCol size="3">
            <IonText>Estado del sensor</IonText>
          </IonCol>
          <IonCol
            size="2"
            onClick={() =>
              version === "Version Firmware"
                ? setVersion("Version Hardware")
                : setVersion("Version Firmware")
            }
          >
            <IonText>{version}</IonText>
          </IonCol>
          <IonCol size="1.2">
            <IonText>Eliminar</IonText>
          </IonCol>
        </IonRow>
        <AllSensorsList title={title} version={version} />
      </IonGrid>
    </IonPage>
  );
};

export default AllSensors;
