import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  isPlatform,
  IonInput,
} from "@ionic/react";
import React, { useContext, useRef, useState } from "react";
import styles from "./PrintReportModal.module.css";
import ReportModal from "./ReportModal";
import AuthInfoContext from "../../../context/authinfo-context";
import axios from "axios";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";

const PrintReportModal: React.FC<{
  trigger: string;
  modal: React.RefObject<HTMLIonModalElement>;
}> = (props) => {
  const { url, token, presentToast } = useContext(AuthInfoContext);
  const modal = props.modal;
  const modalReport = useRef<HTMLIonModalElement>(null);
  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const datetimeBegin = useRef<null | HTMLIonDatetimeElement>(null);
  const dateBeginModal = useRef<null | HTMLIonModalElement>(null);
  const cancelBegin = () => {
    datetimeBegin.current?.cancel();
    dateBeginModal.current?.dismiss();
  };
  const confirmBegin = () => {
    datetimeBegin.current?.confirm();
    dateBeginModal.current?.dismiss();
  };
  const datetimeEnd = useRef<null | HTMLIonDatetimeElement>(null);
  const dateEndModal = useRef<null | HTMLIonModalElement>(null);

  const cancelEnd = () => {
    datetimeEnd.current?.cancel();
    dateEndModal.current?.dismiss();
  };
  const confirmEnd = () => {
    datetimeEnd.current?.confirm();
    dateEndModal.current?.dismiss();
  };
  const selectBeginDate = (event: any) => {
    let begindate = event.target.value.split("T")[0];
    begindate = begindate.split("-");
    setBeginDate(begindate[2] + "/" + begindate[1] + "/" + begindate[0]);
  };
  const selectEndDate = (event: any) => {
    let enddate = event.target.value.split("T")[0];
    enddate = enddate.split("-");
    setEndDate(enddate[2] + "/" + enddate[1] + "/" + enddate[0]);
  };
  const postReport = async () => {
    await axios
      .post(
        url + "splice/myorganization/report",
        { start_date: beginDate, last_date: endDate },
        {
          validateStatus: function (status) {
            return status < 500;
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          presentToast(
            res.data.message,
            checkmarkCircleOutline,
            "success",
            "top"
          );
        } else {
          presentToast(res.statusText, alertCircleOutline, "danger", "top");
        }
      });
  };
  const generateReport = () => {
    postReport();
    modalReport.current?.present();
  };
  const inputDate = (ev: any, beginEnd: boolean) => {
    switch (ev.target.value.toString().length) {
      case 2:
        ev.target.value = ev.target.value + "/";
        break;
      case 5:
        ev.target.value = ev.target.value + "/";
        break;
      case 10:
        if (beginEnd) {
          setBeginDate(ev.target.value);
        } else {
          setEndDate(ev.target.value);
        }
        break;
      default:
        if (beginEnd) {
          setBeginDate(ev.target.value);
        } else {
          setEndDate(ev.target.value);
        }
        break;
    }
  };
  const keyController = (ev: any) => {
    if (ev.key === "Backspace") {
      switch (ev.target.value.toString().length) {
        case 3:
          ev.target.value = ev.target.value.slice(0, 2);
          break;
        case 6:
          ev.target.value = ev.target.value.slice(0, 5);
          break;
        default:
          break;
      }
    }
  };

  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.8;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }

  return (
    <IonModal
      ref={modal}
      trigger={props.trigger}
      initialBreakpoint={isPlatform("desktop") ? 0.4 : calculateHeigthOfModal()}
      breakpoints={isPlatform("desktop") ? [0, 0.4] : [0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        e.target.dismiss();
        setBeginDate("");
        setEndDate("");
      }}
      className={styles.printreportModal}
    >
      <IonContent className="ion-justify-content-center">
      <div className={styles.MainContainer}>
          <IonGrid className={styles.printreportItemTitle}>
            <IonText className={styles.printreportTitle}>
              IMPRIMIR REPORTE
            </IonText>
          </IonGrid>
          <IonGrid className={styles.printreportItem}>
            <IonGrid className={styles.printreportCalendarItem}>
              <IonItem
                id="calendar-begin-modal"
                className={styles.printreportIconCalendar + " ion-no-padding"}
                lines="none"
              >
                <IonIcon
                  className={styles.printreportCalendarIcon}
                  icon="assets/icons/logo-calendar.svg"
                />
              </IonItem>

              <IonText className={styles.printreportCalendarText}>Desde</IonText>
              <IonItem
                className={styles.printreportCalendarButton + " ion-no-padding"}
              >
                <IonInput
                  placeholder="DD/MM/AA"
                  maxlength={10}
                  value={beginDate ? beginDate : ""}
                  onIonChange={(e) => inputDate(e, true)}
                  onKeyDown={(event) => {
                    keyController(event);
                  }}
                  className={styles.printreportCalendarInput}
                />
              </IonItem>
              <IonModal
                trigger="calendar-begin-modal"
                className={styles.printreportCalendarModal}
                backdropDismiss={false}
                ref={dateBeginModal}
              >
                <IonDatetime
                  ref={datetimeBegin}
                  presentation="date"
                  className={styles.printreportCalendar}
                  onIonChange={(ev) => selectBeginDate(ev)}
                  color="warning"
                  firstDayOfWeek={1}
                  
                >
                  <IonButtons slot="buttons">
                    <IonButton color="danger" onClick={cancelBegin}>
                      Cancelar
                    </IonButton>
                    <IonButton color="dark" onClick={confirmBegin}>
                      Seleccionar
                    </IonButton>
                  </IonButtons>
                </IonDatetime>
              </IonModal>
            </IonGrid>
            <IonGrid className={styles.printreportCalendarItem}>
              <IonItem
                id="calendar-end-modal"
                className={styles.printreportIconCalendar + " ion-no-padding"}
                lines="none"
              >
                <IonIcon
                  className={styles.printreportCalendarIcon}
                  icon="assets/icons/logo-calendar.svg"
                />
              </IonItem>
              <IonText className={styles.printreportCalendarText}>Hasta</IonText>
              <IonItem
                className={styles.printreportCalendarButton + " ion-no-padding"}
              >
                <IonInput
                  placeholder="DD/MM/AA"
                  maxlength={10}
                  value={endDate ? endDate : ""}
                  onIonChange={(e) => inputDate(e, false)}
                  onKeyDown={(event) => {
                    keyController(event);
                  }}
                  className={styles.printreportCalendarInput}
                />
              </IonItem>
              <IonModal
                trigger="calendar-end-modal"
                className={styles.printreportCalendarModal}
                backdropDismiss={false}
                ref={dateEndModal}
              >
                <IonDatetime
                  ref={datetimeEnd}
                  presentation="date"
                  className={styles.printreportCalendar}
                  onIonChange={(ev) => selectEndDate(ev)}
                  color="warning"
                  firstDayOfWeek={1}
                >
                  <IonButtons slot="buttons">
                    <IonButton color="danger" onClick={cancelEnd}>
                      Cancelar
                    </IonButton>
                    <IonButton color="dark" onClick={confirmEnd}>
                      Seleccionar
                    </IonButton>
                  </IonButtons>
                </IonDatetime>
              </IonModal>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.printreportItemButton}>
            <IonButton
              className={styles.printreportButton}
              onClick={generateReport}
              disabled={
                beginDate &&
                endDate &&
                beginDate < endDate &&
                endDate !== "" &&
                beginDate !== "" &&
                beginDate.length === 10 &&
                endDate.length === 10
                  ? false
                  : true
              }
              expand="block"
              fill="outline"
            >
              <IonText className={styles.printreportButtonText}> ACEPTAR </IonText>
            </IonButton>
          </IonGrid>
        </div> 
      </IonContent>
      <ReportModal parentModal={modal} modal={modalReport} />
    </IonModal>
  );
};

export default PrintReportModal;
