import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import styles from "./SensorList.module.css";
import SensorStateModal from "../../Modals/Sensors/SensorStateModal";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { Splice } from "../../../context/interfaces-context";
import ListLoading from "../../Loading/ListLoading";
import SpliceModal from "../../Modals/Splices/SpliceModal";

const SensorList: React.FC = () => {
  const { socket, url, token } = useContext(AuthInfoContext);
  const [items, setItems] = useState<Splice[]>([]);
  const idSensor = useRef<number>(0);
  const idSplice = useRef<string>("");
  const idBlueprint = useRef<string>("");
  const side = useRef<string>("");
  const company = useRef<string>("");
  const phoneCompany = useRef<string>("");
  const modal = useRef<HTMLIonModalElement>(null);
  const modalSplice = useRef<HTMLIonModalElement>(null);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [percentage, setPercentage] = useState<boolean>(true);

  const getSplice = async (event?: any) => {
    const respuesta = await axios.get(
      url + `splice/myorganization?limit=60&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if (loading) {
      setLoading(false);
    }
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSplice(event);
      } else {
        getSplice();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  useEffect(() => {
    if (!isPlatform("desktop")) {
      //generateItems();
      getSplice();
    }
    socket?.on("spliceChanged", () => {
      currentPage.current = 1;
      getSplice();
      if (idSensor.current !== 0) {
        socket?.emit("sensorStateJoin", {
          token: token,
          idSensor: idSensor.current,
        });
      }
    });
    return () => {
      if (isPlatform("desktop")) {
        getSplice();
      }
      socket?.off("spliceChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showSensorState = (id: number, idsplice: string) => {
    idSensor.current = id;
    idSplice.current = idsplice;
    socket?.emit("sensorStateJoin", { token: token, idSensor: id });
    modal.current?.present();
  };

  const showSpliceInfo = (splice: Splice) => {
    idSplice.current = splice._id as string;
    idBlueprint.current = splice.idBlueprint as string;
    side.current = splice.side as string;
    company.current = splice.company as string;
    phoneCompany.current = splice.phone_company as string;
    modalSplice.current?.present();
  };
  if (loading) {
    return (
      <IonContent className={styles.sensorListContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.sensorListContent}>
        {items.map((item, index) => (
          <IonRow
            key={index}
            className={styles.sensorListRow}
            id="open-sensor-state"
          >
            <IonCol size="1.8">
              <IonText className={styles.sensorListPK}>{item.pk}</IonText>
            </IonCol>

            <IonCol size="4.68" onClick={() => showSpliceInfo(item)}>
              <IonText className={styles.sensorListName}>{item.name}</IonText>
              <IonText className={styles.sensorListRoute}>{item.infrastructure}</IonText>
            </IonCol>

            <IonCol
              onClick={() =>
                percentage ? setPercentage(false) : setPercentage(true)
              }
              size="3.36"
            >
              <IonText className={styles.sensorListPercentage}>
                {item.mixed_luminaire
                  ? item.number_of_luminaries_calculated + "%"
                  : percentage
                  ? item.number_of_luminaries_calculated + "%"
                  : (parseInt(item.number_of_luminaries_calculated as string) *
                      parseInt(item.number_of_luminaries as string)) /
                    100}
              </IonText>
            </IonCol>

            <IonCol size="2.16">
              <IonIcon
                className={styles.sensorListIcon}
                icon={
                  item.repair
                    ? "assets/icons/state-repair.svg"
                    : !item.within_the_schedule
                    ? "assets/icons/state-anormal.svg"
                    : item.state
                    ? "assets/icons/state-normal.svg"
                    : "assets/icons/state-anormal.svg"
                }
                onClick={() =>
                  showSensorState(item.idSensor as number, item._id as string)
                }
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <SensorStateModal
          idSplice={idSplice}
          idSensor={idSensor}
          modal={modal}
        />
        <SpliceModal
          idSplice={idSplice}
          idBlueprint={idBlueprint}
          company={company}
          side={side}
          phoneCompany={phoneCompany}
          modal={modalSplice}
        />
      </IonContent>
    );
  }
};

export default SensorList;
