import {
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
  isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./OrganizationUsersList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import { User } from "../../../../context/interfaces-context";
import ListLoading from "../../../Loading/ListLoading";
import ApproveUserModal from "../../../Modals/Users/ApproveUserModal";

const OrganizationUsersList: React.FC = () => {
  const { socket, url, token } = useContext(AuthInfoContext);
  const [items, setItems] = useState<User[]>([]);
  const modal = useRef<HTMLIonModalElement>(null);
  const [verifyUser, setVerifyUser] = useState<boolean>(false);
  const [idUser, setIdUser] = useState<string>("");
  const [roleUser, setRoleUser] = useState<string>("");
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const getSplice = async (event?: any) => {
    const respuesta = await axios.get(
      url + `user/byorganization/${id}?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if(loading){setLoading(false)}
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSplice(event);
      } else {
        getSplice();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  useEffect(() => {
    if(!isPlatform('desktop')){
      generateItems();
    }
    socket?.on("userRolesChanged", () => {
      currentPage.current = 1;
      getSplice();
    });
    return () => {
      if(isPlatform('desktop')){
        generateItems();
      }
      socket?.off("userRolesChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showuserState = (
    verify: boolean | undefined,
    id: string | undefined,
    role: string[] | undefined
  ) => {
    setVerifyUser(verify as boolean);
    setIdUser(id as string);
    if (role) {
      setRoleUser(role[0]);
    }
    modal.current?.present();
  };

  const userName = (user: User) => {
    if (user.names && user.last_names) {
      let name = user.names.split(" ")[0];
      let lastName = user.last_names.split(" ")[0];
      return name + " " + lastName;
    } else {
      return "";
    }
  };
  const userRole = (user: User) => {
    let typeUser = "";
    if (user.roles) {
      switch (user.roles[0]) {
        case "SuperAdministrator":
          typeUser = "0";
          break;
        case "Administrator":
          typeUser = "1";
          break;
        case "Operator":
          typeUser = "2";
          break;
        default:
          break;
      }
    }
    return typeUser;
  };
  if (loading) {
    return (
      <IonContent className={styles.userListContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.userListContent}>
        {items.map((item, index) => (
          <IonRow
            key={item.email}
            className={styles.userListRow}
            id="open-user-state"
          >
            <IonCol size="5.5" className={styles.userListCol1}>
              <IonText>{userName(item)}</IonText>
            </IonCol>
            <IonCol size="4" className={styles.userListCol}>
              <IonText>{userRole(item)}</IonText>
            </IonCol>
            <IonCol size="2.5" className={styles.userListCol}>
              <IonIcon
                className={styles.userListIcon}
                icon={
                  item.verify
                    ? "assets/icons/request-user-verify.svg"
                    : "assets/icons/request-user-pending.svg"
                }
                onClick={
                  item.verify
                    ? () => showuserState(true, item._id, item.roles)
                    : () => showuserState(false, item._id, item.roles)
                }
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <ApproveUserModal
          modal={modal}
          checked={verifyUser}
          id={idUser}
          role={roleUser}
        />
      </IonContent>
    );
  }
};

export default OrganizationUsersList;
