import React, { useContext } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import Welcome from "../Welcome/Welcome";
import Home from "../Home/Home";
import Loading from "../../components/Loading/Loading";
import SuperAdmin from "../SuperAdmin/SuperAdmin";

const Main: React.FC = () => {
  const { logged, loadData, userRol, myUser } = useContext(AuthInfoContext);
  return (
    <>
      {loadData ? (
        logged ? (
          myUser.roles ? (
            userRol === "SuperAdministrator" ? (
              <SuperAdmin />
              ) : (

              <Home />
            )
          ) : (
            <Loading />
          )
        ) : (
          <Welcome />
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Main;
