import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import AuthInfoContext, { AuthInfoContextModel } from "./authinfo-context";
import { isPlatform, useIonToast } from "@ionic/react";
import axios from "axios";
import { alertCircleOutline } from "ionicons/icons";
import {
  ClientToServerEvents,
  ServerToClientEvents,
  User,
} from "./interfaces-context";
import { Socket, io } from "socket.io-client";
import { App as AppListener } from "@capacitor/app";
import { set as setStorage, remove as removeStorage } from "./../data/Storage";

const dayMilisecond = 86400000;

const AuthInfoContextProvider: React.FC<PropsWithChildren> = (props) => {
  const url = "https://api.sunandplayapp.duckdns.org/";
  const socket = useRef<Socket<ServerToClientEvents, ClientToServerEvents>>();
  const [logged, setLogged] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const tokenDate = useRef<number>(0);
  const [code, setCode] = useState("");
  const [myUser, setMyUser] = useState<User>({} as User);
  const [userRol, setUserRol] = useState<string>("");
  const reconnectSocket = useRef<boolean>(false);
  const [loadData, setLoadData] = useState<boolean>(false);
  useEffect(() => {
    //console.log('uwucito')
    if (!isPlatform("desktop")) {
      AppListener.addListener("appStateChange", ({ isActive }) => {
        if (!isActive) {
          socket.current?.disconnect();
        } else if (isActive) {
          if (!socket.current?.connected) {
            socket.current?.connect();
          }
        }
      });
    }
    //setLoadData(true)
    return () => {
      //console.log('owocito')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logIn = async (token: string) => {
    if (!socket.current?.connected) {
      socket.current = io(url);
      socket.current.on("connect", async () => {
        if (reconnectSocket.current === true) {
          console.log("Reconectando con el socket");
          if (
            Date.now() < tokenDate.current + dayMilisecond * 14 &&
            Date.now() >= tokenDate.current + dayMilisecond * 7
          ) {
            console.log("Renovando token");
            socket.current?.emit("userNewToken", token);
          } else if (Date.now() > tokenDate.current + dayMilisecond * 7) {
            logOut();
          }
        } else {
          console.log("Conectado al socket");
        }
        socket.current?.emit("join", token);
      });
      socket.current.on("fota", (msg) => {
        console.log(msg);
      });
      socket.current.on("userNewToken", (msg) => {
        setToken(msg);
        tokenDate.current = Date.now();
        setStorage("token", msg);
        setStorage("tokenDate", tokenDate.current);
        setStorage("logged", true);
        setLogged(true);
      });
      socket.current.on("disconnect", () => {
        console.log("Perdida de conexion del socket");
        reconnectSocket.current = true;
      });
    }
    tokenDate.current = Date.now();
    setStorage("token", token);
    setStorage("tokenDate", tokenDate.current);
    setStorage("logged", true);
    setLogged(true);
  };
  const [present] = useIonToast();
  const presentToast = (
    message: string,
    icon: string,
    color:
      | "danger"
      | "dark"
      | "light"
      | "medium"
      | "primary"
      | "secondary"
      | "success"
      | "tertiary"
      | "warning"
      | undefined,
    position: "top" | "bottom" | "middle" | undefined,
    duration?: number | undefined
  ) => {
    present({
      message: message,
      duration: duration ? duration : 1000,
      position: position,
      icon: icon,
      color: color,
    });
  };
  const logOut = () => {
    presentToast("Sesion Cerrada", alertCircleOutline, "danger", "top", 1000);
    removeStorage("token");
    removeStorage("tokenDate");
    removeStorage("logged");
    removeStorage("userRol");
    socket.current?.disconnect();
    setLogged(false);
    setToken("");
    tokenDate.current = 0;
    setCode("");
    setMyUser({} as User);
    setUserRol('');
    reconnectSocket.current = false
  };
  const getMyUser = async (token: string) => {
    await axios
      .get(url + "user/profile", {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((respuesta) => {
        if (respuesta.status === 200) {
          setMyUser({
            _id: respuesta.data._id,
            names: respuesta.data.names,
            last_names: respuesta.data.last_names,
            email: respuesta.data.email,
            company: respuesta.data.company,
            phone: respuesta.data.phone,
            roles: respuesta.data.roles,
            emailNotify: respuesta.data.emailNotify,
            organization: respuesta.data.organization,
          });

          setUserRol(respuesta.data.roles[0]);
          setStorage("userRol", respuesta.data.roles[0]);
        } else {
          presentToast(
            "Error al cargar los datos del usuario",
            alertCircleOutline,
            "danger",
            "top",
            1000
          );
        }
      })
      .catch((res) => setTimeout(() => getMyUser(token), 5000));
  };
  const authContext: AuthInfoContextModel = {
    logged,
    url,
    token,
    code,
    myUser,
    socket: socket.current,
    loadData,
    userRol,
    setLogged,
    setToken,
    setCode,
    setLoadData,
    setUserRol,
    logIn,
    logOut,
    getMyUser,
    presentToast,
  };
  return (
    <AuthInfoContext.Provider value={authContext}>
      {props.children}
    </AuthInfoContext.Provider>
  );
};

export default AuthInfoContextProvider;
