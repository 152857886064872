import { IonButton, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import React from "react";
import styles from "./ReportModal.module.css";
import { useContext } from "react";
import AuthInfoContext from "../../../context/authinfo-context";
const ReportModal: React.FC<{
  parentModal: React.RefObject<HTMLIonModalElement>;
  modal: React.RefObject<HTMLIonModalElement>;
}> = (props) => {
  const { myUser } = useContext(AuthInfoContext);
  const redirectTo = () => {
    props.modal.current?.dismiss();
    props.parentModal.current?.dismiss();
  };
  return (
    <IonModal ref={props.modal} className={styles.reportsModal}>
      <IonGrid className={styles.reportsContainer}>
        <IonRow className={styles.reportsRow}>
          <IonText className={styles.reportsText}>
          El reporte esta siendo procesado y se enviará en los próximos minutos a su correo {myUser.email}
          </IonText>
        </IonRow>
        <IonRow className={styles.reportsRow}>
          <IonButton
            className={styles.reportsButton}
            expand="block"
            fill="outline"
            onClick={redirectTo}
          >
            ACEPTAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ReportModal;
