import {
  IonPage,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButton,
} from "@ionic/react";
import React, { useState } from "react";
import Debug from "./Debug/Debug";
import Fota from "./Fota/Fota";

const Terms: React.FC = () => {
  const [pageSelect,setPageSelect] = useState<string>('')
  return (
    <IonPage>
      <IonHeader className="ion-no-border ">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Menú principal</IonTitle>
        </IonToolbar>
      </IonHeader>
        <IonButtons>
          <IonButton onClick={()=>setPageSelect('FOTA')} style={pageSelect === 'FOTA' ? {background:'white'} : {background:'none'}}>FOTA</IonButton>
          <IonButton onClick={()=>setPageSelect('Debug')} style={pageSelect === 'Debug' ? {background:'white'} : {background:'none'}}>Debug</IonButton>
        </IonButtons>
      <IonContent fullscreen>
        {pageSelect === 'Debug' ? <Debug/> : <Fota/>}
      </IonContent>
    </IonPage>
  );
};

export default Terms;
