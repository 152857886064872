import React, { useContext, useEffect, useState } from "react";
import styles from "./SensorStateModal.module.css";
import {
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonText,
  IonToggle,
} from "@ionic/react";
import { sensorData } from "../../../context/interfaces-context";
import AuthInfoContext from "../../../context/authinfo-context";
import { isPlatform } from "@ionic/react";



const SensorStateModal: React.FC<{
  idSplice: React.MutableRefObject<string>;
  idSensor: React.MutableRefObject<number>;
  modal: React.RefObject<HTMLIonModalElement>;
}> = (props) => {
  const { socket } = useContext(AuthInfoContext);
  const modal = props.modal;
  const [sensorState, setSensorState] = useState<sensorData>({
    power_supply: true,
    number_of_luminaries_state: true,
    coverage: 4,
    within_the_schedule: true,
  } as sensorData);
  const signalBar = (signal: number) => {
    switch (signal) {
      case 1:
        return "assets/icons/sensor-state-one-bar-signal.svg";
      case 2:
        return "assets/icons/sensor-state-two-bar-signal.svg";
      case 3:
        return "assets/icons/sensor-state-three-bar-signal.svg";
      case 4:
        return "assets/icons/sensor-state-good-signal.svg";
      default:
        return "assets/icons/sensor-state-bad-signal.svg";
    }
  };
  useEffect(() => {
    socket?.on("sensorStateData", (data: sensorData) => {
      setSensorState(data);
    });
    return () => {
      socket?.off("sensorStateData");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeRepairMode = (e: any) => {
    socket?.emit("spliceRepairChange", {
      idSplice: props.idSplice.current,
      repair: e.target.checked,
    });
  };
  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*1.07;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }
  return (
    <IonModal
      ref={modal}
      initialBreakpoint={isPlatform("desktop") ? 0.85 : calculateHeigthOfModal()}
      breakpoints={isPlatform("desktop") ? [0,0.25,0.35,0.5,0.7,0.85] : [0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        props.idSensor.current = 0;
        socket?.emit("sensorStateLeave", { idSensor: props.idSensor.current });
        e.target.dismiss();
      }}
      className={styles.modalstateModal}
      
    >
      <IonContent className="ion-justify-content-center">
        <div className={styles.mainContainer}>
          <IonGrid className={styles.modalstateItem}>
            <IonIcon
              icon={
                sensorState.within_the_schedule
                  ? `assets/icons/sensor-state-good-withinschedule.svg`
                  : `assets/icons/sensor-state-bad-withinschedule.svg`
              }
              className={styles.modalstateIcon}
            />
            <IonGrid className={styles.modalstateTextContainer}>
              <IonText>Función fuera de horario</IonText>
              <IonText>
                última conexion {sensorState.within_the_schedule_date} hrs
              </IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.modalstateItem}>
            <IonIcon
              icon={signalBar(sensorState.coverage)}
              className={styles.modalstateIcon}
            />
            <IonGrid className={styles.modalstateTextContainer}>
              <IonText>Comunicación sensor</IonText>
              <IonText>última conexion {sensorState.coverage_date} hrs</IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.modalstateItem}>
            <IonIcon
              icon={
                sensorState.power_supply
                  ? `assets/icons/sensor-state-good-energy.svg`
                  : `assets/icons/sensor-state-bad-energy.svg`
              }
              className={styles.modalstateIcon}
            />
            <IonGrid className={styles.modalstateTextContainer}>
              <IonText>Energía del empalme</IonText>
              <IonText>
              última conexion {sensorState.power_supply_date} hrs
              </IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.modalstateItem}>
            <IonIcon
              icon={
                sensorState.number_of_luminaries_state
                  ? `assets/icons/sensor-state-good-lum.svg`
                  : `assets/icons/sensor-state-bad-lum.svg`
              }
              className={styles.modalstateIcon}
            />
            <IonGrid className={styles.modalstateTextContainer}>
              <IonText>Cantidad de luminarias</IonText>
              <IonText>
              última conexion {sensorState.number_of_luminaries_state_date} hrs
              </IonText>
            </IonGrid>
          </IonGrid>
          <IonGrid className={styles.modalstateItemRepair}>
            <IonText>Modo reparación</IonText>
            <IonToggle
              className={styles.modalstateToggle}
              checked={sensorState ? sensorState.repair : false}
              onClick={(e) => changeRepairMode(e)}
            ></IonToggle>
          </IonGrid>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SensorStateModal;
