import { IonRow, IonCol, IonText, IonIcon, IonItem } from "@ionic/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import styles from "./OrganizationData.module.css";
import ListLoading from "../../../Loading/ListLoading";
import {
  closeCircleOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import ConfirmDeleteOrganization from "../../../Modals/Organization/ConfirmDeleteOrganization";

const OrganizationData: React.FC<{
  id: string;
  index: number;
  verify: boolean;
}> = (props) => {
  const { url, token, socket, presentToast } = useContext(AuthInfoContext);
  const [data, setData] = useState<{
    usersVerify: number;
    usersTotal: number;
    splice: number;
    sensorReady: number;
    sensorAmount: number;
    idOrganization: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const confirmModal = useRef<HTMLIonModalElement>(null)
  const requestDeleteOrganization = () =>{
    confirmModal.current?.present()
    //deleteOrganization()
  }
  const deleteOrganization = async () => {
    const response = await axios.delete(url + `organization/${props.id}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    presentToast(response.data.message, alertCircleOutline, "danger", "top");
    confirmModal.current?.dismiss()
  };
  useEffect(() => {
    socket?.on("organizationDataAmount", (msg) => {
      if (props.id === msg.idOrganization) {
        setLoading(true);
        setData(msg);
        setLoading(false);
      }
    });
    return () => {
      //socket?.off("userChanged");
      socket?.off("organizationDataAmount");
      /*
      document.addEventListener("uwu", (e) => {
        console.log(e.detail.uwu)
        setLoading(true);
        getData(props.id);
      });*/
      /*if (!data) {
        getData(props.id);
      }*/
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const organizationAprove = () => {
    socket?.emit("organizationVerify", {
      idOrganization: props.id,
      token: token,
    });
  };
  return (
    <>
      {loading ? (
        <ListLoading />
      ) : (
        <>
          <IonRow key={props.id + "1"} className={styles.organizationdataRow}>
            <IonCol size="6">Datos</IonCol>
            <IonCol size="6">Cantidad</IonCol>
          </IonRow>
          <IonRow key={props.id + "2"} className={styles.organizationdataRow}>
            <IonCol size="6">Usuarios</IonCol>
            <IonCol size="4">
              <IonText>{data?.usersVerify}</IonText>
              <IonText>/</IonText>
              <IonText>{data?.usersTotal}</IonText>
            </IonCol>
            <IonCol size="2">
              <IonItem
                className={styles.organizationdataItem + " ion-no-padding"}
                routerLink={`/page/OrganizationUsers?id=${props.id}`}
                routerDirection="none"
              >
                <IonIcon icon="assets/icons/admin-users.svg" slot="start" />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow key={props.id + "3"} className={styles.organizationdataRow}>
            <IonCol size="6">Empalmes</IonCol>
            <IonCol size="4">
              <IonText>{data?.splice}</IonText>
            </IonCol>
            <IonCol size="2">
              <IonItem
                className={styles.organizationdataItem + " ion-no-padding"}
              >
                <IonIcon icon="assets/icons/admin-splices.svg" slot="start" />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow key={props.id + "4"} className={styles.organizationdataRow}>
            <IonCol size="6">Sensores</IonCol>
            <IonCol size="4">
              <IonText>{data?.sensorReady}</IonText>
              <IonText>/</IonText>
              <IonText>{data?.sensorAmount}</IonText>
            </IonCol>
            <IonCol size="2">
              <IonItem
                className={styles.organizationdataItem + " ion-no-padding"}
                routerLink={`/page/OrganizationSensors?id=${props.id}`}
                routerDirection="none"
              >
                <IonIcon icon="assets/icons/admin-sensors.svg" slot="start" />
              </IonItem>
            </IonCol>
          </IonRow>
          {props.verify ? (
            <IonRow
              key={props.id + "5"}
              className={styles.organizationdataRowDisapprove}
            >
              <IonCol size="12" onClick={organizationAprove}>
                <IonText>Desaprobar</IonText>
                <IonIcon icon={closeCircleOutline}></IonIcon>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow
              key={props.id + "6"}
              className={styles.organizationdataRowApprove}
            >
              <IonCol size="12" onClick={organizationAprove}>
                <IonText>Aprobar</IonText>
                <IonIcon icon={checkmarkCircleOutline} />
              </IonCol>
            </IonRow>
          )}
          <IonRow
            key={props.id + "7"}
            className={styles.organizationdataRowDelete}
          >
            <IonCol size="12" onClick={requestDeleteOrganization}>
              <IonIcon icon="assets/icons/admin-delete.svg"></IonIcon>
            </IonCol>
          </IonRow>
        </>
      )}
      <ConfirmDeleteOrganization modal={confirmModal} deleteOrganization={deleteOrganization}/>
    </>
  );
};

export default OrganizationData;
