import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import styles from "./EditSensorList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { Splice, encodeB64 } from "../../../context/interfaces-context";
import EditSensorModal from "../../Modals/Sensors/EditSensorModal";
import { useHistory } from "react-router";
import ListLoading from "../../Loading/ListLoading";

const EditSensorList: React.FC = () => {
  const { socket, url, token } = useContext(AuthInfoContext);
  const [items, setItems] = useState<Splice[]>([]);
  const modal = useRef<HTMLIonModalElement>(null);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [ID, setID] = useState<string>("");
  const [codeSensor, setCodeSensor] = useState<string>("");
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    socket?.on("spliceChanged", () => {
      currentPage.current = 1;
      getSplice();
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("spliceChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSplice = async (event?: any) => {
    const respuesta = await axios.get(
      url + `splice/myorganization?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    setLoading(false);
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSplice(event);
      } else {
        getSplice();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const comfirmDelete = (id: string, codeSensor: string) => {
    setID(id);
    setCodeSensor(codeSensor);
    modal.current?.present();
  };
  if (loading) {
    return (
      <IonContent className={styles.editsensorContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.editsensorContent}>
        {items.map((item, index) => (
          <IonRow
            key={index}
            className={styles.editsensorRow}
            id="open-sensor-state"
          >
            <IonCol size="4">
              <IonText>{item.code}</IonText>
            </IonCol>
            <IonCol size="5">
              <IonText>{item.name}</IonText>
              <IonText>{item.infrastructure}</IonText>
            </IonCol>
            <IonCol size="1.5">
              <IonIcon
                className={styles.editsensorIcon}
                icon={"assets/icons/logo-edit-sensor.svg"}
                onClick={() =>
                  history.push(`/page/EditSensorForm?sensor=${encodeB64(item)}`)
                }
              ></IonIcon>
            </IonCol>
            <IonCol size="1.5">
              <IonIcon
                className={styles.editsensorIcon}
                icon={"assets/icons/logo-delete-sensor.svg"}
                onClick={() =>
                  comfirmDelete(item._id as string, item.code as string)
                }
              />
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <EditSensorModal modal={modal} id={ID} codeSensor={codeSensor} />
      </IonContent>
    );
  }
};

export default EditSensorList;
