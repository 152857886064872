/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import styles from "./Home.module.css";
import { printOutline } from "ionicons/icons";
import { useContext, useEffect, useRef } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import SensorList from "../../components/Lists/SensorList/SensorList"
import SensorsModal from "../../components/Modals/Sensors/SensorsModal"
import PrintReportModal from "../../components/Modals/Report/PrintReportModal"

const Home: React.FC = () => {
  const { socket, logOut, token, getMyUser } = useContext(AuthInfoContext);
  const sensorModal = useRef<HTMLIonModalElement>(null);
  const printReportModal = useRef<HTMLIonModalElement>(null);
  useEffect(() => {
    if (!isPlatform("desktop")) {
      socket?.on("userLogout", () => {
        logOut();
      });
      socket?.on("userChangedProfile", () => {
        getMyUser(token);
      });
    }
    return () => {
      if (isPlatform("desktop")) {
        socket?.on("userLogout", () => {
          logOut();
        });
        socket?.on("userChangedProfile", () => {
          getMyUser(token);
        });
      }
    };
  }, []);

  return (
    <IonPage>
        <IonHeader className="ion-no-border ion-no-padding ion-no-margin">
          <IonToolbar className={styles.homeHeader}>
            <IonButtons slot="start" className={styles.homeHeaderMenuButton}>
              <IonMenuButton className={styles.homeHeaderMenuButtonIcon}/>
            </IonButtons>
            {isPlatform("desktop") ? (
              <></>
            ) : (
              <IonTitle className={styles.homeTitleBar}>
                Menú principal
              </IonTitle>
            )}
            <IonButtons slot="end" className={styles.homeHeaderButtons}>
              <IonButton
                onClick={() => printReportModal.current?.present()}
                className={styles.homeButtonIconPrint}
                shape="round"
              >
                <IonIcon
                  className={styles.homeIconsPrint}
                  icon={printOutline}
                />
              </IonButton>
              <IonButton
                onClick={() => sensorModal.current?.present()}
                className={styles.homeButtonIcon}
                shape="round"
              >
                <IonIcon
                  className={styles.homeIcons}
                  icon="assets/icons/logo-add-sensor.svg"
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>


        <IonGrid className={styles.homeTitleContainer}>
          <IonTitle className={styles.homeTitle}>ESTADO REAL</IonTitle>
          <IonGrid className={styles.homeStatesContainer}>
            <IonText className={styles.homeState}>
              NORMAL
              <IonIcon
                icon="assets/icons/state-normal.svg"
                className={styles.homeIcon}
              />
            </IonText>
            <IonText className={styles.homeState}>
              ANORMAL
              <IonIcon
                icon="assets/icons/state-anormal.svg"
                className={styles.homeIcon}
              />
            </IonText>
            <IonText className={styles.homeState}>
              REPARACIÓN
              <IonIcon
                icon="assets/icons/state-repair.svg"
                className={styles.homeIcon}
              />
            </IonText>
          </IonGrid>
        </IonGrid>


        <IonGrid className={styles.sensorListContainer}>
          <IonRow className={styles.sensorListHeader + " ion-no-padding"}>
            <IonCol size="1.8" className="ion-no-padding">
              <IonText className="ion-vertical-align-middle ">PK</IonText>
            </IonCol>
            <IonCol size="4.68" className="ion-no-padding">
              <IonText className="ion-vertical-align-middle">Nombre</IonText>
            </IonCol>
            <IonCol size="3.36" className="ion-no-padding">
              <IonText className={styles.sensorListHeaderNL}>Número de Luminarias</IonText>
            </IonCol>
            <IonCol size="2.16" className="ion-no-padding">
              <IonText>Estado</IonText>
            </IonCol>
          </IonRow>

          <SensorList />
        
        </IonGrid>
        <SensorsModal modal={sensorModal} />
        <PrintReportModal
          trigger={"open-print-modal"}
          modal={printReportModal}
        />
    </IonPage>
  );
};

export default Home;
