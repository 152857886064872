import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import styles from "./OrganizationSensorsList.module.css";
import axios from "axios";
import AuthInfoContext from "../../../../context/authinfo-context";
import ListLoading from "../../../Loading/ListLoading";
import { Sensor } from '../../../../context/interfaces-context';

const OrganizationSensorsList: React.FC<{
  version: string;
  title: string;
  id:string;
}> = ({ version, title,id }) => {
  const { socket, url, token } = useContext(AuthInfoContext);
  const [items, setItems] = useState<Sensor[]>([]);
  const currentPage = useRef<number>(1);
  const hasNextPage = useRef<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const otpCode = useRef("");

 useEffect(() => {
    if (!isPlatform("desktop")) {
      generateItems();
    }
    socket?.on("sensorChanged", () => {
      currentPage.current = 1;
      getSensors();
    });
    socket?.on("sensorOtpGenerated", (msg) => {
      otpCode.current = msg;
    });
    return () => {
      if (isPlatform("desktop")) {
        generateItems();
      }
      socket?.off("sensorChanged");
      socket?.off("sensorOtpGenerated");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSensors = async (event?: any) => {
    const respuesta = await axios.get(
      url + `sensor/byorganization/${id}?limit=15&page=${currentPage.current}`,
      {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (respuesta.data.hasNextPage) {
      currentPage.current = currentPage.current + 1;
    }
    hasNextPage.current = respuesta.data.hasNextPage;
    setItems([...items, ...respuesta.data.docs]);
    if(loading){
      setLoading(false);
    }
    if (event) {
      event.target.complete();
    }
  };

  const generateItems = (event?: any) => {
    if (hasNextPage.current) {
      if (event) {
        getSensors(event);
      } else {
        getSensors();
      }
    } else {
      if (event) {
        event.target.complete();
      }
    }
  };
  const getSensorCode = (code: string | null, id: number) => {
    if (code === "S/C") {
      socket?.emit("sensorOtpGenerate", { idSensor: id, token: token });
    }
  };
  if (loading) {
    return (
      <IonContent className={styles.editsensorContent}>
        <ListLoading />
      </IonContent>
    );
  } else {
    return (
      <IonContent className={styles.editsensorContent}>
        {items.map((item, index) => (
          <IonRow
          key={index}
          className={styles.editsensorRow}
          id="open-sensor-state"
          >
            <IonCol
              size="5.4"
              className={styles.editsensorCol1}
              onClick={(e) =>
                getSensorCode(
                  e.currentTarget.childNodes.item(0).textContent,
                  item._id as number
                  )
                }
                >
              <IonText>
                {title === "Codigo"
                  ? item.code !== ''
                    ? item.code
                    : "S/C"
                  : item.serial_number}
              </IonText>
            </IonCol>
            <IonCol size="1">
              <IonText>{item._id}</IonText>
            </IonCol>
            <IonCol size="3.4">
              <IonText>{item.state}</IonText>
            </IonCol>
            <IonCol size="2.2">
              <IonText>{ version === "Version Firmware"
                  ? item.firmware_version
                  : item.hardware_version}</IonText>
            </IonCol>
          </IonRow>
        ))}
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(ev);
          }}
          threshold="75%"
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    );
  }
};

export default OrganizationSensorsList;
