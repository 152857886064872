import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import EditSensorList from "../../../components/Lists/EditSensorList/EditSensorList";
import styles from "./EditSensor.module.css";
import { useHistory } from "react-router";

const EditSensor: React.FC = () => {
  const history = useHistory()
  const backTo = () => {
    history.replace('/Main')
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.codeButtons}>
            <IonButton shape="round" onClick={backTo}>
              <IonIcon
                className={styles.codeIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.editsensorTitleContainer}>
        <IonTitle className={styles.editsensorTitle}>EDITAR SENSORES</IonTitle>
      </IonGrid>
      <IonGrid className={styles.editsensorContainer}>
        <IonRow className={styles.editsensorHeader + " ion-no-padding"}>
          <IonCol size="4" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle ">ID</IonText>
          </IonCol>
          <IonCol size="5" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle">Nombre</IonText>
          </IonCol>
          <IonCol size="1.5" className="ion-no-padding">
            <IonIcon className={styles.editsensorIcon} icon='assets/icons/logo-config.svg'/>
          </IonCol>
          <IonCol size="1.5" className="ion-no-padding">
          <IonIcon className={styles.deletesensorIcon} icon='assets/icons/logo-delete.svg'/>
          </IonCol>
        </IonRow>
        <EditSensorList />
      </IonGrid>
    </IonPage>
  );
};

export default EditSensor;
