import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import styles from "./MosquitoUsers.module.css";
import MosquitoUsersList from "../../../components/Lists/SuperAdmin/MosquitoUsersList/MosquitoUsersList";
import { addCircleOutline } from "ionicons/icons";

const MosquitoUsers: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border ">
        <IonToolbar className={styles.homeHeader}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className={styles.homeTitleBar}>Menú principal</IonTitle>
          <IonButtons slot="end" className={styles.homeHeaderButtons}>
            <IonButton
              id="open-modal"
              className={styles.homeButtonIcon}
              shape="round"
              routerLink="/page/CreateMosquitoUser"
            >
              <IonIcon className={styles.homeIcons} icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.adminuserTitleContainer}>
        <IonTitle className={styles.adminuserTitle}>USUARIOS MOSQUITO</IonTitle>
      </IonGrid>
      <IonGrid className={styles.userListContainer}>
        <IonRow className={styles.userListHeader}>
          <IonCol size="5">
            <IonText>Username</IonText>
          </IonCol>
          <IonCol size="5">
            <IonText>Password</IonText>
          </IonCol>
          <IonCol size="2">
            <IonText>Edit</IonText>
          </IonCol>
        </IonRow>
        <MosquitoUsersList />
      </IonGrid>
    </IonPage>
  );
};

export default MosquitoUsers;
