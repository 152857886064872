import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToggle,
} from "@ionic/react";
import React, { useContext, useRef } from "react";
import styles from "./ApproveUserModal.module.css";
import AuthInfoContext from "../../../context/authinfo-context";


const ApproveUserModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  checked: boolean;
  id: string;
  role: string;
}> = (props) => {
  const modal = props.modal;
  const modalToggle = useRef<HTMLIonToggleElement>(null);
  const modalSelect = useRef<HTMLIonSelectElement>(null);
  const { socket } = useContext(AuthInfoContext);
  const approveUser = () => {
    socket?.emit("userRole", {
      id: props.id,
      roles: modalSelect.current?.value,
      verify: modalToggle.current?.checked as boolean,
    });
    modal.current?.dismiss();
  };
  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.8;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }
  return (
    <IonModal
      ref={modal}
      initialBreakpoint={calculateHeigthOfModal()}
      breakpoints={[0, calculateHeigthOfModal()]}
      canDismiss
      backdropDismiss
      onWillDismiss={(e) => {
        e.target.dismiss();
      }}
      className={styles.approveuserModal}
    >
      <IonContent className="ion-justify-content-center">
        <div className={styles.mainContainer}>
          <IonGrid className={styles.approveuserItemIcon}>
            <IonIcon
              icon="assets/icons/setting-user.svg"
              className={styles.approveuserIcon}
            />
          </IonGrid>


          <IonGrid className={styles.approveuserItem}>
            <IonText className={styles.approveuserText}>Tipo de usuario:</IonText>
            <IonSelect
              value={props.role}
              ref={modalSelect}
              className={styles.approveuserSelect}
              placeholder="Seleccionar"
              interface="alert"
            >
              <IonSelectOption
                className={styles.approveuserOption}
                value="Administrator"
              >
                Administrador{" (1)"}
              </IonSelectOption>

              <IonSelectOption 
                value="Operator"
                className={styles.approveuserOption}
              >
                Operador{" (2)"}
              </IonSelectOption>
            </IonSelect>
          </IonGrid>

          
          <IonGrid className={styles.approveuserItem}>
            <IonText className={styles.approveuserText}>
              Aprobar Solicitud:
            </IonText>
            <IonToggle
              ref={modalToggle}
              checked={props.checked}
              className={styles.approveuserToggle}
            ></IonToggle>
          </IonGrid>

          <IonGrid className={styles.approveuserItemButton}>
            <IonButton
              className={styles.approveuserButton}
              expand="block"
              fill="outline"
              onClick={() => approveUser()}
            >
              <IonItem className={styles.approveuserButtonText}>ACEPTAR</IonItem>
            </IonButton>
          </IonGrid>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ApproveUserModal;
