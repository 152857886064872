import React, { useContext, useRef, useState } from "react";
import styles from "./BlueprintModal.module.css";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import axios from "axios";
import {
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import AuthInfoContext from "../../../context/authinfo-context";

const BlueprintModal: React.FC<{
  trigger: string;
  idSplice: string | null;
}> = ({ trigger, idSplice }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { url, token, presentToast } = useContext(AuthInfoContext);
  const dataFile = useRef<FormData>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file,setFile] = useState<any>()
  const fileName = useRef<string>();

  const uploadBlueprintFile = (event: any) => {
    const file = event.target.files[0];
    setFile(file)
    fileName.current = file.name;
    const data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    dataFile.current = data;
  };
  const sendFile = async () => {
    await axios
      .post(url + "blueprint/upload", dataFile.current, {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        console.log(res);
        if (res.status === 201) {
          await axios
            .put(
              url + `splice/${idSplice}`,
              {
                idBlueprint: res.data.blueprint._id,
              },
              {
                validateStatus: function (status) {
                  return status < 500;
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                presentToast(
                  res.data.message,
                  checkmarkCircleOutline,
                  "success",
                  "top"
                );
              } else {
                presentToast(
                  res.statusText,
                  alertCircleOutline,
                  "danger",
                  "top"
                );
              }
            });
        } else {
          presentToast(res.statusText, alertCircleOutline, "danger", "top");
        }
      })
      .catch((res) =>
        presentToast(res.status, alertCircleOutline, "danger", "top", 1000)
      );
  };
  const handleClick = (event: any) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <IonModal
      ref={modal}
      trigger={trigger}
      initialBreakpoint={ isPlatform("desktop")? 0.5:0.35}
      breakpoints={isPlatform("desktop") ? [0,0.45]:[0, 0.35]}
      className={styles.blueprintModal}
    >
      <IonContent className="ion-padding ion-justify-content-center">
        <IonGrid className={styles.blueprintContainer}>
          <IonGrid className={styles.blueprintItem}>
            <IonRow> 
              <IonButton
                onClick={handleClick}
                className={styles.blueprintSelectButton}
              >
                <IonIcon
                  className={styles.blueprintSelectIcon}
                  icon='assets/icons/add-file.svg'
                />
              </IonButton>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={uploadBlueprintFile}
                accept=".pdf"
                style={{ visibility: "hidden", display: "none" }}
                className={styles.blueprintSelectBlueprint}
              />
              {/*<IonText>
                {file ? `${file.name}` : "Ningún archivo seleccionado"}
  </IonText>*/}
            </IonRow>
          </IonGrid>
          <IonGrid className={styles.blueprintItem}>
            <IonRow>
                <IonText style={{padding:'5px 0'}}>{file ? `${file.name}` : "Agregar nuevo documento"}</IonText>
            </IonRow>
          </IonGrid>
          <IonGrid className={styles.blueprintItem}>
            <IonRow>
              <IonButton
                className={styles.blueprintUploadButton}
                onClick={sendFile}
              >
                <IonText>Cargar</IonText>
              </IonButton>
            </IonRow>
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default BlueprintModal;
