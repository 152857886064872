import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import {
  checkmarkCircleOutline,
  alertCircleOutline,
  addCircleOutline,
} from "ionicons/icons";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import AuthInfoContext from "../../../../context/authinfo-context";
import styles from "./CreateMosquitoUser.module.css";
import { UserMqtt } from '../../../../context/interfaces-context';

const CreateMosquitoUser: React.FC = () => {
  const { token, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory();
  const submitButton = useRef<HTMLIonButtonElement>();
  const password = useRef<string | undefined>("");
  const username = useRef<string | undefined>("");
  const superUser = useRef<boolean | undefined>(false);
  const [acls, setAcls] = useState<string[]>([""]);
  const aclsRef = useRef<HTMLIonColElement[]>([]);

  const checkValues = () => {
    if (username.current && password.current && superUser.current) {
      submitButton.current?.removeAttribute("disabled");
    }
  };
  const signinUserMqtt = async (data: UserMqtt) => {
    const respuesta = await axios.post(url + "sensor/usersmqtt", data, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (respuesta.status === 201) {
      presentToast(
        respuesta.data.message,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      history.goBack();
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };
  const submit = () => {
    if (username.current && password.current && superUser.current) {
      signinUserMqtt({
        username: username.current as string,
        passwordPlain: password.current as string,
        superuser: superUser.current as boolean,
        acls: generateArrayAcls(),
      });
    } else {
      presentToast(
        "Ingrese las datos para poder registrarse",
        alertCircleOutline,
        "danger",
        "top"
      );
    }
  };
  const addAcls = () => {
    setAcls([...acls, ""]);
  };
  const generateArrayAcls = () => {
    const aclsArray = aclsRef.current.map((acls, index) => ({
      acc: (acls.childNodes[1].childNodes[0] as HTMLIonSelectElement)
        .value as number,
      topic: (
        acls.childNodes[0].childNodes[1].childNodes[0] as HTMLIonInputElement
      ).value as string,
    }));
    return aclsArray;
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.signinButtons}>
            <IonButton
              shape="round"
              routerLink="/page/MosquitoUsers"
              routerDirection="back"
            >
              <IonIcon
                className={styles.signinIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className={styles.container} >
          <IonTitle className={styles.signinTitle} size="large">
            CREAR USUARIO
          </IonTitle>
          <IonIcon
            className={styles.mosquitoIcon}
            icon="assets/icons/mosquitto.svg"
          />
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Nombre de usuario</IonLabel>
                <IonInput
                  placeholder="Nombre Usuario"
                  onIonChange={(e) => {
                    checkValues();
                    username.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonLabel position="floating">Contraseña</IonLabel>
                <IonInput
                  type="password"
                  placeholder="Contraseña"
                  onIonChange={(e) => {
                    checkValues();
                    password.current = e.target.value?.toString();
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput}>
                <IonText className={styles.alertmodalText}>
                  SuperUsuario
                </IonText>
                <IonToggle
                  className={styles.alertmodalToggle}
                  onIonChange={(e) => (superUser.current = e.target.checked)}
                ></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonGrid>
            {acls.map((item, index) => (
              <IonRow key={"acls" + index} className={styles.signinRow}>
                <IonCol
                  className="ion-text-center"
                  ref={(el) =>
                    (aclsRef.current[index] = el as HTMLIonColElement)
                  }
                >
                  <IonItem className={styles.signinInput}>
                    <IonLabel position="floating">Topico</IonLabel>
                    <IonInput placeholder="Nombre" />
                  </IonItem>
                  <IonItem className={styles.signinInput}>
                    <IonSelect
                      interface="popover"
                      placeholder="Seleccionar Acc"
                    >
                      <IonSelectOption value={1} key={0}>
                        1 - Solo Lectura
                      </IonSelectOption>
                      <IonSelectOption value={2} key={1}>
                        2 - Solo Escritura
                      </IonSelectOption>
                      <IonSelectOption value={3} key={2}>
                        3 - Lectura y Escritura
                      </IonSelectOption>
                      <IonSelectOption value={4} key={3}>
                        4 - Solo Suscripcion
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
            ))}
          </IonGrid>
          <IonRow>
            <IonIcon
              className={styles.createMoquitoUserAddIcon}
              icon={addCircleOutline}
              onClick={addAcls}
            />
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.signinButton}
                expand="block"
                fill="outline"
                onClick={submit}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateMosquitoUser;
