import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import styles from "./MyProfile.module.css";
import { useHistory } from "react-router";
import { useRef, useContext } from "react";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { User } from "../../../context/interfaces-context";
import ChangePasswordModal from "../../../components/Modals/ChangePassword/ChangePasswordModal";
import ConfirmUserChange from "../../../components/Modals/User/ConfirmUserChange";
import { userInfo } from "os";

const MyProfile: React.FC = () => {
  const { myUser, presentToast, token, url } = useContext(AuthInfoContext);
  const data = useRef<User>({
    names: myUser.names,
    last_names: myUser.last_names,
    email: myUser.email,
    phone: myUser.phone,
  });
  const history = useHistory();
  const invalidEmailNote = useRef<HTMLIonNoteElement>();
  const submitButton = useRef<HTMLIonButtonElement>();
  const name = useRef<string | undefined>(myUser.names);
  const lastName = useRef<string | undefined>(myUser.last_names);
  const numberContact = useRef<string | undefined>(myUser.phone);
  const email = useRef<string | undefined>(myUser.email);
  const changePasswordModal = useRef<HTMLIonModalElement>(null);
  const confirmModal = useRef<HTMLIonModalElement>(null);

// desactivar boton de aceptar por defecto
submitButton.current?.setAttribute("disabled", "");


  const backTo = () => {
    history.replace("/Main");
  };
  const validateEmail = (event: any) => {
    console.log(email.current);
    console.log(myUser.email)
    if (event.target.value) {
      checkValues();
      if (!validEmail(event.target.value)) {
        submitButton.current?.setAttribute("disabled", "");
        invalidEmailNote.current?.removeAttribute("hidden");
      } else {
        email.current = event.target.value;
        invalidEmailNote.current?.setAttribute("hidden", "");
      }
    } else {
      invalidEmailNote.current?.setAttribute("hidden", "");
    }
  };
  const validEmail = (email: any) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  const checkValues = () => {
    if (
      validEmail(email.current) &&
      name.current &&
      lastName.current &&
      numberContact.current&&
      email.current&&
      (
      myUser.names !== name.current ||
      myUser.last_names !== lastName.current||
      myUser.phone !== numberContact.current||
      myUser.email !== email.current
      )
    ) {
      data.current = {
        names: name.current,
        last_names: lastName.current,
        email: email.current,
        phone: numberContact.current,
      };
      submitButton.current?.removeAttribute("disabled");
    } else {
      submitButton.current?.setAttribute("disabled", "");
    }
  };
  const updateUser = async (data: User) => {
    console.log(data);
    const respuesta = await axios.put(url + "user/profile", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    });
    if (respuesta.status === 409) {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    } else if (respuesta.status === 200) {
      presentToast(
        respuesta.data.message,
        checkmarkCircleOutline,
        "success",
        "top"
      );
      confirmModal.current?.dismiss();
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };
  const submit = () => {
    if (
      email.current &&
      name.current &&
      lastName.current &&
      numberContact.current
    ) {
      updateUser({
        names: name.current,
        last_names: lastName.current,
        email: email.current,
        phone: numberContact.current,
      });
    }
  };
  const requestEmail = async () => {
    await axios
      .post(
        url + "auth/forgotpasswordemail",
        { email: data?.current.email },
        {
          validateStatus: function (status) {
            return status < 500;
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          presentToast(
            res.data.message,
            checkmarkCircleOutline,
            "success",
            "top"
          );
          changePasswordModal.current?.present();
        } else {
          presentToast(res.data.message, alertCircleOutline, "danger", "top");
        }
      })
      .catch((res) => console.log(res.message));
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className={styles.myprofileToolBar}>
          <IonButtons slot="start" className={styles.myprofileButtons}>
            <IonButton
              shape="round"
              className={styles.myprofileButtonIcon}
              onClick={backTo}
            >
              <IonIcon
                className={styles.myprofileIcon}
                icon="assets/icons/logo-vector.svg"
              />
              <IonText className={styles.myprofileIconText}>
                Menú Principal
              </IonText>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>


        
        <IonGrid className={styles.container}>

          <IonTitle className={styles.myprofileTitle} size="large">
            MI PERFIL
          </IonTitle>
          <IonIcon
            className={styles.myprofileIconPerson}
            icon='assets/icons/icon-myprofile.svg'
          />

          <IonRow className={styles.myprofileRow}>
            <IonCol className={styles.myprofileCol}>
              <IonItem className={styles.myprofileInput}>
                <IonLabel position="stacked" color="dark" >
                  <div className={styles.myprofileInputLabel}>
                    Nombres
                  </div>
                </IonLabel>
                <IonInput
                  placeholder="Nombres"
                  className={styles.myprofileInputinside}
                  onIonChange={(e) => {
                    name.current = e.target.value?.toString();
                    checkValues();
                    
                  }}
                  value={data?.current.names}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.myprofileRow}>
            <IonCol className={styles.myprofileCol}>
              <IonItem className={styles.myprofileInput}>
                <IonLabel position="floating" color="dark">
                  <div className={styles.myprofileInputLabel}>
                    Apellidos
                  </div>
                </IonLabel>
                <IonInput
                  placeholder="Apellidos"
                  className={styles.myprofileInputinside}
                  onIonChange={(e) => {
                    lastName.current = e.target.value?.toString();
                    checkValues();
                    
                  }}
                  value={data?.current.last_names}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.myprofileRow}>
            <IonCol className={styles.myprofileCol}>
              <IonItem className={styles.myprofileInput}>
                <IonLabel position="floating" color="dark">
                <div className={styles.myprofileInputLabel}>
                  Email
                </div>
                </IonLabel>
                <IonInput
                  placeholder="correo@dominio.cl"
                  type="email"
                  className={styles.myprofileInputinside}
                  onIonChange={(e) => {
                    email.current = e.target.value?.toString();
                    validateEmail(e);
                  }}
                  value={data?.current.email}
                />
                <IonNote
                  slot="error"
                  hidden
                  ref={(el: HTMLIonNoteElement) =>
                    (invalidEmailNote.current = el)
                  }
                >
                  Email Invalido
                </IonNote>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={styles.myprofileRow}>
            <IonCol className={styles.myprofileCol}>
              <IonItem className={styles.myprofileInput}>
                <IonLabel position="floating" color="dark">
                <div className={styles.myprofileInputLabel}>
                  Número de contacto
                </div>
                </IonLabel>
                <IonInput
                  placeholder="+569 12345678"
                  inputMode="numeric"
                  type="tel"
                  className={styles.myprofileInputinside}
                  onIonChange={(e) => {
                    numberContact.current = e.target.value?.toString();
                    checkValues();

                  }}
                  value={data?.current.phone}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          
          <IonRow className={styles.myprofileRowBelow}>
            <IonCol className={styles.myprofileColBelow}>
              <IonText className={styles.loginLink} onClick={requestEmail}>
                Cambiar contraseña
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow className={styles.myprofileRowBelow}>
            <IonCol className={styles.myprofileColBelow}>
              <IonButton
                className={styles.myprofileButton}
                expand="block"
                fill="outline"
                onClick={() => confirmModal.current?.present()}
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                <IonItem className={styles.myprofileButtonText}>
                  ACEPTAR
                </IonItem>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <ChangePasswordModal
          modal={changePasswordModal}
          email={email}
          userEmail={data?.current.email}
        />
        <ConfirmUserChange modal={confirmModal} submit={submit} />
      </IonContent>
    </IonPage>
  );
};

export default MyProfile;
