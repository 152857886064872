import { IonButton, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import React, { useContext } from "react";
import styles from "./EditSensorModal.module.css";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
const EditSensorModal: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  id: string;
  codeSensor:string;
}> = (props) => {
  const { url, token ,presentToast} = useContext(AuthInfoContext);
  const deleteSensor = async () => {
    const respuesta = await axios.delete(url + `splice/${props.id}`, {
      validateStatus: function (status) {
        return status < 500;
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(respuesta.status === 200){
      props.modal.current?.dismiss()
      presentToast(respuesta.data.message, checkmarkCircleOutline, "success",'top');
    }else{
      presentToast(respuesta.statusText, alertCircleOutline, "danger",'top');
    }
  };
  return (
    <IonModal ref={props.modal} className={styles.modalModal}>
      <IonGrid className={styles.modalContainer}>
        <IonRow className={styles.modalRow}>
          <IonText className={styles.modalText}>
            Estas seguro de querer borrar definitivamente el sensor {props.codeSensor}{" "}
            de tus dispositivos
          </IonText>
        </IonRow>
        <IonRow className={styles.modalRowButton}>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={() => {
              props.modal.current?.dismiss();
            }}
          >
            CANCELAR
          </IonButton>
          <IonButton
            className={styles.modalButton}
            expand="block"
            fill="outline"
            onClick={deleteSensor}
          >
            ACEPTAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default EditSensorModal;
