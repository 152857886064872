import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useRef } from "react";
import styles from "./ConfirmDeleteSensor.module.css";
const ConfirmDeleteSensor: React.FC<{
  modal: React.RefObject<HTMLIonModalElement>;
  deleteSensor: ()=>void
}> = ({ modal , deleteSensor}) => {
  const input = useRef<string | undefined>('')
  const doneButton = useRef<HTMLIonButtonElement>(null)
  const checkValue =(e:any)=>{
    if(e.target.value.toString() === 'ELIMINAR'){
      doneButton.current?.removeAttribute('disabled')
    }else{
      doneButton.current?.setAttribute('disabled','true')
    }
  }
  return (
    <IonModal ref={modal} className={styles.confirmdeleteSensorModal}>
      <IonGrid className={styles.confirmdeleteSensorContainer}>
        <IonRow className={styles.confirmdeleteSensorRow + ' ion-justify-content-center ion-no-padding'}>
          <IonText className={styles.confirmdeleteSensorTittle + " ion-no-padding"}>
            Ingresa la palabra ELIMINAR para confirmar que deseas eliminar el sensor
          </IonText>
        </IonRow>
        <IonRow className={styles.confirmdeleteSensorRow}>
          <IonItem className={styles.confirmdeleteSensorInput}>
            <IonLabel position="floating" color="dark">
            </IonLabel>
            <IonInput
              placeholder="ELIMINAR"
              required
              type="text"
              onIonChange={(e)=> {checkValue(e);input.current = e.target.value?.toString()}}
            />  
          </IonItem>
        </IonRow>
        <IonRow className={styles.confirmdeleteSensorRowButton}>
          <IonCol size="5.5">
            <IonButton
              className={styles.confirmdeleteSensorButton}
              expand="block"
              fill="outline"
              disabled
              ref={doneButton}
              onClick={deleteSensor}
            >
              ACEPTAR
            </IonButton>
          </IonCol>
          <IonCol size="5.5">
            <IonButton
              className={styles.confirmdeleteSensorButton}
              expand="block"
              fill="outline"
              onClick={() => modal.current?.dismiss()}
            >
              CANCELAR
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ConfirmDeleteSensor;
