import React from "react";
import styles from "./SensorsModal.module.css";
import {
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  isPlatform
} from "@ionic/react";

const SensorsModal: React.FC<{ modal: React.RefObject<HTMLIonModalElement>; }> = (props) => {
  const modal = props.modal;
  const calculateHeigthOfModal = ()=>{
    let H = window.innerWidth*0.65;
    let H_rel = H/window.innerHeight;
    return H_rel;
  }
  return (
    <IonModal
      ref={modal}
      initialBreakpoint= {isPlatform("desktop") ? 0.45 : calculateHeigthOfModal()}
      breakpoints={ isPlatform("desktop") ? [0, 0.45] : [0, calculateHeigthOfModal()] }
      className={styles.modalModal}
    >
      <IonContent className= "ion-justify-content-center">
        <div className={styles.MainContainer}>
          <IonGrid className={styles.mainGrid}>
            <IonRow className="ion-justify-content-center">
              <IonIcon
                icon="assets/icons/logo-sensor.svg"
                className={styles.modalIconSensor}
              />
            </IonRow>
            <IonItem
              className={styles.modalItem + " ion-no-padding"}
              routerLink={"/page/AddSensorCode"}
              routerDirection="none"
              lines="none"
              onClick={() => modal.current?.dismiss()}
            >
              <IonIcon
                icon="assets/icons/add-sensor.svg"
                className={styles.modalIcon}
              />
              <IonText className={styles.modalText}>Agregar Sensor</IonText>
            </IonItem>
            <IonItem
              className={styles.modalItem + " ion-no-padding"}
              routerLink={"/page/EditSensor"}
              routerDirection="none"
              lines="none"
              detail={false}
              onClick={() => modal.current?.dismiss()}
            >
              <IonIcon
                icon="assets/icons/setting-sensor.svg"
                className={styles.modalIcon}
              />
              <IonText className={styles.modalText}>Editar Sensores</IonText>
            </IonItem>
          </IonGrid>
        </div>
        {/*
        <IonItem
          className={styles.modalItem+' ion-no-padding'}
          routerDirection="none"
          lines="none"
        >
          <IonIcon
            icon="assets/icons/delete-sensor.svg"
            className={styles.modalIcon}
          />
          <IonText className={styles.modalText}>Eliminar Sensores</IonText>
        </IonItem>*/}
      </IonContent>
    </IonModal>
  );
};

export default SensorsModal;
