import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./SuperAdmin.module.css";
import { addCircleOutline } from "ionicons/icons";
import { useContext, useEffect } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import OrganizationList from "../../components/Lists/SuperAdmin/OrganizationsList/OrganizationList";

const SuperAdmin: React.FC = () => {
  const { socket, logOut } = useContext(AuthInfoContext);
  useEffect(() => {
    socket?.on("userLogout", () => {
      logOut();
    });
    return () => {
      socket?.off("userLogout");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <IonPage style={{background:'transparent'}}>
      <IonHeader className="ion-no-border ">
        <IonToolbar className={styles.homeHeader}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className={styles.homeTitleBar}>Menú principal</IonTitle>
          <IonButtons slot="end" className={styles.homeHeaderButtons}>
            <IonButton
              id="open-modal"
              className={styles.homeButtonIcon}
              shape="round"
              routerLink="/page/CreateOrganization"
            >
              <IonIcon className={styles.homeIcons} icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.homeTitleContainer}>
        <IonTitle className={styles.homeTitle}>ORGANIZACIONES</IonTitle>
      </IonGrid>
      <IonGrid className={styles.sensorListContainer}>
        <IonRow className={styles.sensorListHeader + " ion-no-padding"}>
          <IonCol size="3" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle ">RUT</IonText>
          </IonCol>
          <IonCol size="3.75" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle">Nombre</IonText>
          </IonCol>
          <IonCol size="3.75" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle ">Direccion</IonText>
          </IonCol>
          <IonCol size="1.5" className="ion-no-padding">
            <IonText>Info.</IonText>
          </IonCol>
        </IonRow>
        <OrganizationList />
      </IonGrid>
    </IonPage>
  );
};

export default SuperAdmin;
