import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import styles from "./SignIn.module.css";
import { useHistory } from "react-router";
import { useRef, useContext } from "react";
import axios from "axios";
import AuthInfoContext from "../../../context/authinfo-context";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { User } from "../../../context/interfaces-context";
import SignInModal from "../../../components/Modals/SignIn/SignInModal";
import SignInCodeModal from "../../../components/Modals/SignIn/SignInCodeModal";

const SignIn: React.FC = () => {
  const { code, presentToast, url } = useContext(AuthInfoContext);
  const history = useHistory();
  const invalidEmailNote = useRef<HTMLIonNoteElement>();
  const noCoincidencenPassNote = useRef<HTMLIonNoteElement>();
  const submitButton = useRef<HTMLIonButtonElement>();
  const name = useRef<string | undefined>("");
  const lastName = useRef<string | undefined>("");
  const numberContact = useRef<string | undefined>("");
  const email = useRef<string | undefined>("");
  const company = useRef<string | undefined>("");
  const password = useRef<string | undefined>("");
  const passwordConfirm = useRef<string | undefined>("");
  const codeVerify = useRef<string>("");
  const verify = useRef<boolean | undefined>(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const codeModal = useRef<HTMLIonModalElement>(null);
  const emailCheck = useRef<HTMLIonIconElement>(null);

  const backToCode = () => {
    history.replace("/CodeSignIn");
  };

  const validateEmail = (event: any) => {
    if (event.target.value) {
      if (!validEmail(event.target.value)) {
        submitButton.current?.setAttribute("disabled", "");
        invalidEmailNote.current?.removeAttribute("hidden");
      } else {
        email.current = event.target.value;
        invalidEmailNote.current?.setAttribute("hidden", "");
      }
      checkValues();
    } else {
      checkValues();
      invalidEmailNote.current?.setAttribute("hidden", "");
    }
  };

  const validEmail = (email: any) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const confirmPassword = () => {
    if (password.current === passwordConfirm.current) {
      noCoincidencenPassNote.current?.setAttribute("hidden", "");
      checkValues();
    } else {
      submitButton.current?.setAttribute("disabled", "");
      noCoincidencenPassNote.current?.removeAttribute("hidden");
    }
  };

  const checkValues = () => {
    if (validEmail(email.current) && name.current && lastName.current) {
      emailCheck.current?.removeAttribute("hidden");
    }
    if (
      validEmail(email.current) &&
      name.current &&
      lastName.current &&
      numberContact.current &&
      company.current &&
      password.current &&
      verify.current &&
      passwordConfirm.current &&
      password.current === passwordConfirm.current
    ) {
      submitButton.current?.removeAttribute("disabled");
    }
  };

  const signinUser = async (user: User) => {
    const respuesta = await axios.post(url + "auth/register", user, {
      validateStatus: function (status) {
        return status < 500;
      },
    });
    if (respuesta.status === 201) {
      if (respuesta.data.isAdmin) {
        presentToast(
          respuesta.data.message,
          checkmarkCircleOutline,
          "success",
          "top"
        );
        history.replace("/Login");
      } else {
        modal.current?.present();
      }
    } else {
      presentToast(respuesta.data.message, alertCircleOutline, "danger", "top");
    }
  };

  const submit = () => {
    if (
      email.current &&
      name.current &&
      lastName.current &&
      numberContact.current &&
      company.current &&
      password.current
    ) {
      signinUser({
        names: name.current,
        last_names: lastName.current,
        email: email.current,
        company: company.current,
        password: password.current,
        phone: numberContact.current.split(" ").join(""),
        code: code,
        roles: [],
      });
    } else {
      presentToast(
        "Ingrese las datos para poder registrarse",
        alertCircleOutline,
        "danger",
        "top"
      );
    }
  };

  const checkEmail = async () => {
    if (validEmail(email.current) && name.current && lastName.current) {
      const response = await axios.post(
        url + "auth/verifyemail",
        {
          names: name.current,
          last_names: lastName.current,
          email: email.current,
        },
        {
          validateStatus: function (status) {
            return status < 500;
          },
        }
      );
      if (response.status === 201) {
        console.log(response.data.code);
        codeVerify.current = response.data.code;
        presentToast(
          "Codigo enviado a su correo",
          checkmarkCircleOutline,
          "success",
          "top"
        );
        codeModal.current?.present();
      }
    } else {
      presentToast(
        "Debes ingresar Nombres, Apellidos y un Email valido para poder verificar tu correo",
        alertCircleOutline,
        "danger",
        "top",
        3000
      );
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.signinButtons}>
            <IonButton shape="round" onClick={backToCode}>
              <IonIcon
                className={styles.signinIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className={styles.signinContainer}>
          <IonTitle className={styles.signinTitle} size="large">
            REGISTRARSE
          </IonTitle>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Nombres
                </IonLabel>
                <IonInput
                  placeholder="Nombres"
                  onIonChange={(e) => {
                    checkValues();
                    name.current = e.target.value?.toString();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Apellidos
                </IonLabel>
                <IonInput
                  placeholder="Apellidos"
                  onIonChange={(e) => {
                    checkValues();
                    lastName.current = e.target.value?.toString();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRowEmail}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInputEmail} lines="none">
                <IonLabel position="floating" color="dark">
                  Email
                </IonLabel>
                <IonInput
                  placeholder="correo@dominio.cl"
                  type="email"
                  onIonChange={(e) => validateEmail(e)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
              <IonIcon
                ref={emailCheck}
                className={styles.signinIconEmail}
                icon="assets/icons/confirmemail-icon.svg"
                onClick={checkEmail}
              />
            </IonCol>
          </IonRow>
          <IonNote
            hidden
            className={styles.signinNote}
            ref={(el: HTMLIonNoteElement) => (invalidEmailNote.current = el)}
          >
            Email Invalido
          </IonNote>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Número de contacto
                </IonLabel>
                <IonInput
                  placeholder="+569 12345678"
                  inputMode="numeric"
                  type="tel"
                  maxlength={13}
                  onIonChange={(e) => {
                    checkValues();
                    numberContact.current = e.target.value?.toString();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className="ion-text-center">
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Empresa
                </IonLabel>
                <IonInput
                  placeholder="Empresa"
                  onIonChange={(e) => {
                    checkValues();
                    company.current = e.target.value?.toString();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className={styles.signinCol}>
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Contraseña
                </IonLabel>
                <IonInput
                  placeholder="Contraseña"
                  type="password"
                  onIonChange={(e) => {
                    password.current = e.target.value?.toString();
                    confirmPassword();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className={styles.signinRow}>
            <IonCol className={styles.signinCol}>
              <IonItem className={styles.signinInput} lines="none">
                <IonLabel position="floating" color="dark">
                  Confirmar Contraseña
                </IonLabel>
                <IonInput
                  placeholder="Confirmar Contraseña"
                  type="password"
                  onIonChange={(e) => {
                    passwordConfirm.current = e.target.value?.toString();
                    confirmPassword();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </IonItem>
            </IonCol>
          </IonRow>
                <IonNote
                  hidden
                  className={styles.signinNote}
                  ref={(el: HTMLIonNoteElement) =>
                    (noCoincidencenPassNote.current = el)
                  }
                >
                  Las Contraseñas No Coinciden
                </IonNote>
          <IonRow>
            <IonCol className="ion-text-center ion-margin-top">
              <IonButton
                className={styles.signinButton}
                expand="block"
                fill="outline"
                onClick={submit}
                disabled
                ref={(el: HTMLIonButtonElement) => (submitButton.current = el)}
              >
                ACEPTAR
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <SignInModal modal={modal} />
        <SignInCodeModal
          modal={codeModal}
          codeVerify={codeVerify}
          verify={verify}
        />
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
