import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import styles from "./Blueprints.module.css";
import { cloudUploadOutline, folderOpenOutline } from "ionicons/icons";
import { useContext, useRef, useState } from "react";
import AuthInfoContext from "../../context/authinfo-context";
import axios from "axios";
import { checkmarkCircleOutline, alertCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import BlueprintList from "../../components/Lists/BlueprintList/BlueprintList";

const Blueprints: React.FC = () => {
  const {  url, token, presentToast } =
    useContext(AuthInfoContext);
  const history = useHistory();
  const dataFile = useRef<FormData>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const fileName = useRef<string>();
  const uploadBlueprintFile = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
    fileName.current = file.name;
    const data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);
    dataFile.current = data;
  };
  const sendFile = async () => {
    await axios
      .post(url + "blueprint/upload", dataFile.current, {
        validateStatus: function (status) {
          return status < 500;
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) =>
        presentToast(
          res.data.message,
          checkmarkCircleOutline,
          "success",
          "top",
          1500
        )
      )
      .catch((res) =>
        presentToast(res.status, alertCircleOutline, "danger", "top", 1000)
      );
  };
  const handleClick = (event: any) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className={styles.loginButtons}>
            <IonButton shape="round" onClick={() => history.goBack()}>
              <IonIcon
                className={styles.loginIcon}
                icon="assets/icons/logo-vector.svg"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className={styles.homeTitleContainer}>
        <IonTitle className={styles.homeTitle}>PLANOS</IonTitle>
      </IonGrid>
      <IonGrid className={styles.sensorListContainer}>
        <IonGrid className={styles.filesItem}>
          <IonRow>
            <IonButton
              onClick={handleClick}
              className={styles.fileSelectButton}
            >
              <IonIcon icon={folderOpenOutline} />
            </IonButton>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadBlueprintFile}
              accept=".pdf"
              style={{ visibility: "hidden", display: "none" }}
              className={styles.filesSelectFile}
            />
            <IonText>
              {file ? `${file.name}` : "Ningún archivo seleccionado"}
            </IonText>
          </IonRow>
          <IonRow>
            <IonButton className={styles.filesUploadButton} onClick={sendFile}>
              <IonText>Subir Archivo</IonText>
              <IonIcon icon={cloudUploadOutline} />
            </IonButton>
          </IonRow>
        </IonGrid>
        <IonRow className={styles.sensorListHeader + " ion-no-padding"}>
          <IonCol size="10" className="ion-no-padding">
            <IonText className="ion-vertical-align-middle">Nombre</IonText>
          </IonCol>
          <IonCol size="2" className="ion-no-padding">
            <IonText>Info.</IonText>
          </IonCol>
        </IonRow>
        <BlueprintList />
      </IonGrid>
    </IonPage>
  );
};

export default Blueprints;
