import {
  IonButton,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import styles from "./DisplayCodeModal.module.css";
import { useContext } from "react";
import AuthInfoContext from "../../../context/authinfo-context";
import { Clipboard } from "@capacitor/clipboard";
import { copyOutline } from "ionicons/icons";
const DisplayCodeModal: React.FC<{
  parentModal: React.RefObject<HTMLIonModalElement>;
  modal: React.RefObject<HTMLIonModalElement>;
  code: string;
}> = (props) => {
  const { myUser , presentToast} = useContext(AuthInfoContext);
  const redirectTo = () => {
    props.modal.current?.dismiss();
    props.parentModal.current?.dismiss();
  };
  const copyToClipboard = async () => {
    await Clipboard.write({ string: props.code });
    presentToast('Codigo copiado al portapapeles','','medium',"bottom")
  };
  return (
    <IonModal ref={props.modal} className={styles.displaycodeModal}>
      <IonGrid className={styles.displaycodeContainer}>
        <IonRow className={styles.displaycodeRow}>
          <IonText className={styles.displaycodeTextCode}>{props.code}</IonText>
          <IonIcon
            icon={copyOutline}
            className={styles.displaycodeCopy}
            onClick={copyToClipboard}
          />
          <IonText className={styles.displaycodeText}>
            El código a sido enviado con exito a su correo {myUser.email}
          </IonText>
        </IonRow>
        <IonRow className={styles.displaycodeRow}>
          <IonButton
            className={styles.displaycodeButton}
            expand="block"
            fill="outline"
            onClick={redirectTo}
          >
            ACEPTAR
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default DisplayCodeModal;
